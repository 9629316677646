
import { Line } from "../generic/Line";
import { ActionButton,ButtonStyle } from "../buttons/ActionButton";
import { useDeviceType } from "../../context/DeviceContext";
import { Icon,SubscribeContainer,Body,Text,Buttons,DataContainer, Svg } from "./index.style";

interface SubscribeDataProps{
  icon:any
  text:string
  btnText?:any
  shopLink:string
}
export const ShopData = ({icon,text,btnText="Shop Now",shopLink}:SubscribeDataProps) => {
  const {isMobile} = useDeviceType()

  
  return (
    <DataContainer id="data-container" $isMobile={isMobile}>        
      <Body>
        <SubscribeContainer>
          <Svg as={icon} width="100px" />
          <Text>{text}</Text>
        
          <Line/>
          <Buttons>
            <ActionButton route={shopLink} label={btnText} width="127px" style={ButtonStyle.dark} fontSize="14px" fontWeight="500"></ActionButton>
          </Buttons>    
        </SubscribeContainer>
      </Body>
  </DataContainer>
  );
};


