import React from 'react'
import { DefaultPage } from '../components/Layout/DefaultPage'
import { Experts } from '../components/experts'

export const MyExpertsPage: React.FC = () => {
    return (
        <DefaultPage>
            <Experts />
        </DefaultPage>
    )
}