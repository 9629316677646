import { useFormHandling } from "../../hooks/formUtils";
import { RequestConfigs } from "../../api";
import { ActionButton,ButtonStyle } from "./ActionButton";
import { useState } from "react";
import { useSession } from "../../context/SessionContext";
import { useEffect } from "react";
import { ExpertBuildConfig } from "../eaBuilder/newBuildNew";



export enum ProdcutType {
  experts = 1,
  courses = 2,
  memberProgram=3,
  expertBuilder=4,
}

interface AddToCartButtonProps {
  productType:ProdcutType
  itemId:number
}



export const AddToCartButton = ({productType,itemId } : AddToCartButtonProps) => {

  const {session} = useSession()

  const checkInCart = ()=>{
    if(session.cart.filter(item=>item.id===itemId && item.type===productType).length>0){
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    setIncart(checkInCart())
  }, [session.cart]);

  
  const [inCart,setIncart] = useState(checkInCart())
  const {handleClick} = useFormHandling({requestType:RequestConfigs.addToCart,data:{id:itemId,type:productType}})
  

  
  const buttonClick = ()=>{
    setIncart(true)
    handleClick()
  }


  return (
    <ActionButton
      label={inCart?"In cart":"Add to Cart"}
      width="110px"
      height="36px"
      fontSize="14px"
      fontWeight="500"
      style={ButtonStyle.light}
      onClick={inCart?()=>{}:buttonClick}
    />
  );
};

