import React from 'react';
import styled from 'styled-components';
import { Icons } from '../../constants/Images';
import { Colors } from '../../theme/colors';




// SocialPanel component to house the social login options

interface StarsProps{
  score:number,
  scoreCount:number
}
export const Stars: React.FC<StarsProps> = ({score,scoreCount}) => {
  
  const generateStars = ()=>{
    const totalStars = Math.round(score)
    const icons = []
    for (let i = 0; i < totalStars; i++) {
      icons.push(<Icon src={Icons.StarIcon} key={i}/>)
  }
  return icons
  }

  return (
    <StarsContainer>
      <Text>{score}</Text>
      {generateStars()} 
      <Text>{"("+scoreCount+")"}</Text> 
    </StarsContainer>
  );
};


// Styled container for the social login buttons
const StarsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 5px;
  font-size: 14px;
  color: ${Colors.text};
`;

const Icon = styled.img`
width: 14px;
`

const Text = styled.div`
`
