import styled from "styled-components";
import { Colors } from "../../theme/colors";
import { Icons } from "../../constants/Images";
import { useDeviceType } from "../../context/DeviceContext";
import React ,{useState} from "react";
import Joi from "joi";
import { ActionButton } from "../buttons/ActionButton";
import { ApiResponse } from "../../api";
import { TextLine } from "../generic/TextLine";
import { PayButton } from "../buttons/PayButton";
import { FieldType,FieldConfig,TextFieldType, Field } from "../fields";
import { useSession } from "../../context/SessionContext";
import { CardsList } from "./CardsList";
import { TextLink } from "../generic/TextLink";
import { FormComponent } from "../form";



const cardNumberField:FieldConfig =  {
    type:FieldType.text,
    name:"cardNumber",
    label:"Card Number",
    joiSchema:Joi.string().creditCard().required().allow("7997285399919009").allow("7012000033330026").allow("7485666666666668").allow("7997285399919009").allow("7444333322221111").allow("7005519200000004").allow("7166676667666746"),
    typeOptions:{type:TextFieldType.creditCard}
  }



const expirationField:FieldConfig =   {
    type:FieldType.text,
    name:"expiration",
    label:"MM/YY",
    joiSchema:Joi.string().pattern(/^(0[1-9]|1[0-2])\/([0-9]{2})$/).required(),
    typeOptions:{type:TextFieldType.expiration}
  }
const cvv:FieldConfig = {
    type:FieldType.text,
    name:"cardSecurityCode",
    label:"CVV",
    joiSchema:Joi.string().pattern(/^[0-9]+$/).length(3).required(),
    typeOptions:{type:TextFieldType.number,maxLength:3}
  }


interface PaymentFormProps{
    serverRes?: ApiResponse | null
    onChange:(name:string,value:any,valid:boolean)=>void;
    onClick:()=>void;
}

export const PaymentForm: React.FC<PaymentFormProps>= ({serverRes,onChange,onClick}) => {
    
    const {isMobile} = useDeviceType()
    const {session} = useSession()
    const hasPayment = session.paySources && session.paySources?.length>0?true:false
    const [isNewCard,setIsNewCard] = useState<boolean>(!hasPayment)
    
    const onCardSelect = (paySourceId: number | null) => {
      
      
      if(paySourceId){
        setIsNewCard(false)
        onChange("cardNumber","",true)
        onChange("cardSecurityCode","",true)
        onChange("expiration","",true)
        onChange("paySourceId",paySourceId|| "",true)
      }
      
    }

    const onCardAdd = () => {
      setIsNewCard(true)
      onChange("paySourceId","",true)
    }

    

      return (
        <>   
        <Box $isMobile={isMobile}>
            <FormComponent fields={[]} title="Payment Information" serverRes={serverRes} onChange={()=>{}} align="flex-start"/>
           {  hasPayment && 
           
           <>
             <TextLine>Saved Cards</TextLine>
            <Spacer/>
            <CardsList sources={session.paySources} enabled={!isNewCard} onChange={onCardSelect}></CardsList>
           </>
           
           }
            
            {
                hasPayment && !isNewCard &&
                <>
                
                <TextLink onClick={onCardAdd}>+ New Card</TextLink>
                <Spacer/>
                <Spacer/>
                </>    
            }
            <FormContainer>

            {
              isNewCard && 
              <>
              <TextLine>Pay with Card</TextLine>
              <Spacer/>
              <Field 
                fieldConfig={cardNumberField}
                onChange={onChange}
                serverRes={serverRes}
              />
              <Content>

              <Field 
                fieldConfig={expirationField}
                onChange={onChange}
                serverRes={serverRes}
              />
              <Field 
                fieldConfig={cvv}
                onChange={onChange}
                serverRes={serverRes}
              />
              </Content>
              </>
            }
            
            <ActionButton onClick={onClick} label="Submit" width="100%" height="48px"/>
              
            </FormContainer> 
            <Spacer/>
            <Spacer/>
            <TextLine>Other Payments Methods</TextLine>
            <Spacer/>
            <Spacer/>
            <PayButton name="Google Pay" icon={Icons.GooglePay}/>
            <Spacer/>
            <PayButton name="Apple Pay" icon={Icons.ApplePay}/>
        
           
        
        </Box>   
        
        </>
      );
    };

    const Box = styled.div<{$isMobile:boolean}>`
    display: flex;
    flex-direction: column;
    justify-content:center;
    background-color: ${Colors.whiteBackground};
    align-items: flex-start;
    border:${prop=>prop.$isMobile?'none' : '1px solid ' + Colors.fieldBorder} ;
    padding:${prop=>prop.$isMobile?'none' : '20px'} ;
    border-radius: 8px;
    `

    const Content = styled.div`
    display: flex;
    justify-content:center;
    align-items: flex-start;
    gap:10px;
    width: 48%;
    margin-bottom: 10px;
    `

   
const FormContainer = styled.div`
display:flex;
flex-direction:column;
align-items: center; /* This will center the items horizontally */
justify-content: center; /* This will center the items vertically and space them evenly */
background-color: ${Colors.whiteBackground};
width: 100%;
`

export const Spacer = styled.div`
  margin: 5px;
`;




const Title=styled.h3`
margin: 0;
margin-bottom: 10px;
align-self:flex-start;
`

