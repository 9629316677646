import styled from "styled-components";
import { Colors } from "../../theme/colors";
import { Images } from "../../constants/Images";

export const TextContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
  width: 88%;
`;

export const Text = styled.p`
  margin: 0px;
`;

export const ColumnBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RowBox = styled.div`
  display: flex;
`;

export const HomepageContainer = styled.div`
  background-color: ${Colors.whiteBackground};
  width: 100%;
`;

export const ExplanationContainer = styled.div`
  padding-top: 90px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  align-items: center;
  color: ${Colors.whiteBackground};
`;

export const EmpowerContainer = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url(${Images.GraphWithMan});
  background-color: ${Colors.blackBackground};
  height: 100vh;
  z-index: 10;
  @media screen and (max-width: 1280px) {
    padding: 0 20px;
  }
  @media screen and (max-width: 768px) {
    background-image: url(${Images.MobileGraph});
  }
`;




export const StartButton = styled.button`
  background-color: ${Colors.primary};
  padding: 12px 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  color: ${Colors.whiteBackground};
`;

export const BottomImage = styled.img`
  bottom: 0px;
  width: 100%;
`;

export const ProfitContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: "23.22px";
`;


export const CoinImage = styled.img`
  width: 64px;
  height: 64px;
`;

export const CarouselContainer = styled.div`
  background-color: ${Colors.whiteBackground};
  padding: 80px 0px;
  max-width: 1200px;
  /* width: 100%; */
  margin: 0 auto;

  @media screen and (max-width: 1280px) {
    padding: 80px 40px;
  }

  @media screen and (max-width: 992px) {
    padding: 60px 20px;
  }

  @media screen and (max-width: 768px) {
    padding: 40px 24px;
  }
`;

export const ElevateContainer = styled.div`
  background-color: ${Colors.whiteBackground};
  padding: 80px 0px;
  max-width: 1200px;
  margin: 0 auto;

  @media screen and (max-width: 1280px) {
    padding: 80px 40px;
  }

  @media screen and (max-width: 992px) {
    padding: 60px 20px;
  }

  @media screen and (max-width: 768px) {
    padding: 40px 24px;
  }
`;

export const ExcelContainer = styled.div`
  background-color: ${Colors.whiteBackground};
  padding: 80px 0px;
  max-width: 1200px;
  margin: 0 auto;

  @media screen and (max-width: 1280px) {
    padding: 80px 40px;
  }

  @media screen and (max-width: 992px) {
    padding: 60px 24px;
  }

  @media screen and (max-width: 768px) {
    padding: 40px 24px;
  }
`;

export const UnlockContainer = styled.div`
  background-color: ${Colors.grayBackground};
  padding: 80px 0 0 0;

  @media screen and (max-width: 1280px) {
    padding: 80px 40px 0;
  }

  @media screen and (max-width: 992px) {
    padding: 60px 20px;
  }

  @media screen and (max-width: 768px) {
    padding: 40px 24px;
  }
`;

export const StatisticsContainer = styled.div``;
export const CheckIcon = styled.img`
  width: 12px;
  height: 9px;
  padding-top: 4px;
`;

export const PhoneImage = styled.img`
  width: 382px;
  height: 400px;
`;
