import styled from "styled-components";
import { Colors } from "../../../theme/colors";
import React from "react";
import { useSession} from "../../../context/SessionContext";
import { useFormHandling } from "../../../hooks/formUtils";
import { RequestConfigs } from "../../../api";
import { Icons } from "../../../constants/Images";
import { Line } from "../../generic/Line";
import { AddToCartButton } from "../../buttons/AddToCartButton";
import { BuyNowButton } from "../../buttons/BuyNowButton";
import { ProdcutType } from "../../buttons/AddToCartButton";
import { useDictionary } from "../../../context/DictionaryContext";
import { Bullets } from "../../generic/Bullets";





export const NotMember: React.FC = () => {
const {translate} = useDictionary()
  





  return (
    <>
      <Container>
        <Section className="section" >
            <LeftContainer>
              <Title>{translate("membership_title")}</Title>
            </LeftContainer>
            <RightContainer>
              <Title>$100/mo</Title>
            </RightContainer>

        </Section>
        <Section>
          <Text>
              {translate("membership_p1")}
          </Text>
        </Section>

        <Section>
          <BenefitArea>
            <SubTitle>
            {translate("membership_subtitle")}
            </SubTitle>
            <Bullets rows={[translate("membership_benefit1"),translate("membership_benefit2")]}/>
            
            
          </BenefitArea>
        </Section>

        <Line/>
          <Buttons>
            <AddToCartButton itemId={1} productType={ProdcutType.memberProgram}/>
            <BuyNowButton productType={ProdcutType.memberProgram} itemId={1} actionName="Subscribe Now"/>
          </Buttons>   

      </Container>

    </>
  );
};




const Container = styled.div`
display: flex;
flex-direction: column;
flex-wrap: wrap;
justify-content:flex-start;
align-items: flex-start;
width: 100%;
border-radius: 8px;
gap:20px;
padding: 10px;
`

const Section = styled.div`
display: flex;
justify-content: space-between;
width: 100%;
/* flex-direction: column; */
gap:10px;
margin-top: -15px;
margin-bottom: 20px;
`

const Title = styled.h3`
margin-top: -5px;
margin-bottom: 0px;
color: ${Colors.blackText};
font-size: 18px;
font-weight: 600;
`

const SubTitle = styled.div`
font-size: 14px;
color:${Colors.blackText};
font-weight:500;
`

const Text = styled.div`
font-size: 14px;
color:${Colors.text};
`

const BenefitArea = styled.div`
display: flex;
flex-direction:column;
justify-content: flex-start;
width: 100%;
background-color:${Colors.messageBox};
border-radius: 8px;
padding: 20px;
`

const VImage = styled.img`
 position: absolute;
  left: -25px; /* Adjust the positioning of the custom image */
  top: 50%; /* Adjust the positioning of the custom image */
  transform: translateY(-50%); /* Center vertically */
`


const VPoint = styled.ul`
 display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 14px;
  gap: 10px 20px;
  margin-left: 20px;
  padding: 0;
  color: ${Colors.text};
`

const V = styled.li`
 display: flex;
  flex-wrap: wrap;
  gap:10px;
  margin-left: 10px;
  padding: 0;
  position: relative; /* Required for positioning the custom image */
  list-style-type: none; /* Remove default bullet point */
  line-height: 20px; /* Adjust according to your design */
`


const LeftContainer = styled.div`
display: flex;
align-items: center;
gap:10px;
`
const RightContainer = styled.div`
padding: 5px;
display: flex;
gap:5px;
`


const Buttons=styled.div`
display: flex;
justify-content: flex-end;
width: 100%;
margin-top: 15px;
gap:10px;
`
