
import { Wrapper,Title,P,List,ListItem,SubList } from './index.style';

export const Aml = () => {
   

    return (
            <Wrapper>
                <Title>Introduction</Title>
                <P>{process.env.REACT_APP_COMPANY_NAME} ("the Company"), operating the website {`www.${process.env.REACT_APP_DOMAIN_NAME}`}, is committed to preventing money laundering and combating the financing of terrorism (AML/CFT) in compliance with applicable laws and regulations. This Anti-Money Laundering (AML) Policy outlines the procedures and actions {process.env.REACT_APP_COMPANY_NAME} takes to ensure that our products and services are not used to facilitate money laundering or terrorist financing activities.</P>
                <Title>Scope</Title>
                <P>This AML Policy applies to all employees, contractors, and agents of {process.env.REACT_APP_COMPANY_NAME}, as well as to all users of our trading robots, trading courses, and trading signals.</P>
                <Title>Customer Due Diligence (CDD)</Title>
                    <List>
                        <ListItem>Customer Identification and Verification:</ListItem>
                            <SubList>
                                <ListItem>Prior to entering into any business relationship, {process.env.REACT_APP_COMPANY_NAME} will collect and verify the identity of our customers using reliable, independent source documents, data, or information.</ListItem>
                                <ListItem>The information required includes, but is not limited to, full name, date of birth, residential address, and valid government-issued identification.</ListItem>
                            </SubList>
                        <ListItem>Ongoing Monitoring</ListItem>
                            <SubList>
                                <ListItem>{process.env.REACT_APP_COMPANY_NAME} will conduct ongoing monitoring of all transactions to ensure they are consistent with the customer’s profile and business activities.</ListItem>
                                <ListItem>Any suspicious transactions will be investigated, and appropriate action will be taken, including reporting to relevant authorities if necessary.</ListItem>
                            </SubList>
                    </List>
                <Title>Risk Assessment</Title>
                <SubList>
                    <ListItem>{process.env.REACT_APP_COMPANY_NAME} will conduct regular risk assessments to identify and assess the money laundering and terrorist financing risks associated with its products and services.</ListItem>
                    <ListItem>Risk assessments will take into account factors such as customer risk, product/service risk, geographic risk, and transaction risk.</ListItem>
                </SubList>

                <Title>Reporting Suspicious Activity</Title>
                <SubList>
                    <ListItem>All employees are required to report any suspicious activity or transactions to the designated AML Compliance Officer.</ListItem>
                    <ListItem>The AML Compliance Officer will review and investigate all reports of suspicious activity and, if necessary, file Suspicious Activity Reports (SARs) with the relevant authorities.</ListItem>
                </SubList>

                <Title>Record Keeping</Title>
                <SubList>
                    <ListItem>{process.env.REACT_APP_COMPANY_NAME} will maintain all records of customer identification and transaction data for a minimum of five years, or as required by applicable law.</ListItem>
                    <ListItem>Records will be kept in a secure manner and will be readily accessible for review by regulatory authorities upon request.</ListItem>
                </SubList>

                <Title>Training and Awareness</Title>
                <SubList>
                    <ListItem>{process.env.REACT_APP_COMPANY_NAME} will provide regular training to all employees on AML policies and procedures, including how to recognize and report suspicious activity.</ListItem>
                    <ListItem>Training will be updated regularly to reflect changes in AML laws and regulations, as well as emerging risks.</ListItem>
                </SubList>

                <Title>Compliance and Review</Title>
                <SubList>
                    <ListItem>The AML Compliance Officer is responsible for overseeing the implementation and enforcement of this AML Policy.</ListItem>
                    <ListItem>{process.env.REACT_APP_COMPANY_NAME} will conduct regular reviews and audits of its AML program to ensure its effectiveness and compliance with applicable laws and regulations.</ListItem>
                    <ListItem>Any deficiencies identified in the AML program will be promptly addressed and corrected.</ListItem>
                </SubList>

                <Title>Conclusion</Title>
                <P>{process.env.REACT_APP_COMPANY_NAME} is committed to maintaining the highest standards of integrity and compliance in its operations. By adhering to this AML Policy, we aim to prevent and mitigate the risks associated with money laundering and terrorist financing. For any questions or further information regarding this policy, please contact our AML Compliance Officer at support@{process.env.REACT_APP_DOMAIN_NAME}.</P>
                <P>By using www.{process.env.REACT_APP_DOMAIN_NAME}, you agree to comply with this AML Policy and understand that {process.env.REACT_APP_COMPANY_NAME} reserves the right to take appropriate action against any user found to be in violation of this policy.</P>
                
            </Wrapper>
        
    );
};

