import styled from "styled-components";
import { Colors } from "../../../theme/colors";

export const ElevateWrapper = styled.div`
    display: flex;
    gap: 80px;
    @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
`
export const RightContainer = styled.div`
    display: flex;
    flex: 1 1 0;
    gap: 28px;
    flex-direction: column;
    align-items: flex-start;
`
export const TopDesText = styled.div`
    display: flex;
    gap: 24px;
    flex-direction: column;
`
export const CheckList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`
export const CheckItem = styled.div`
    display: flex;
    gap: 6px;
    align-items: center;
`

export const ImageContainer = styled.div`
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 382px;
    margin: auto;
    @media screen and (max-width: 1024px) {
        width: 320px;
    }
    @media screen and (max-width: 768px) {
        width: 100%;
    }
`
export const ImgFront = styled.img`
    position: relative;
    z-index: 5;
    width: 300px;
    margin: auto;
    @media screen and (max-width: 1024px) {
        width: 320px;
    }
    @media screen and (max-width: 768px) {
        width: 100%;
    }
`

export const BackgroundCube = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 80%;
    background-color: ${Colors.secondary};
    z-index: 1;
    border-radius: 100px;
`

export const BackgroundCircle = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 80%;
    background-color: ${Colors.primary};
    z-index: 1;
    border-radius: 100%;
    transform: rotate(130deg)
    
`