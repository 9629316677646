import React from 'react'
import { AuthPage } from '../components/Layout/AuthPage'
import { useFormHandling } from '../hooks/formUtils'
import { RequestConfigs } from '../api'
import { useEffect } from 'react'
import { routesMap } from '../constants/routes'
import { useSession } from '../context/SessionContext'
import { useNavigate } from 'react-router-dom'

export const LogoutPage: React.FC = () => {

    const {handleClick,serverRes} = useFormHandling({requestType:RequestConfigs.logout})
    const {setSession} = useSession()
    const navigate = useNavigate()

    useEffect(() => {
        handleClick()
      }, []);

    useEffect(() => {
        if(serverRes!==null){
            setSession((prevSession) => ({
                ...prevSession,
                account: null
            }));
            navigate(routesMap.homePage.to)
        }
            
    }, [serverRes]);
    

    return (
        <AuthPage>
            <></>
        </AuthPage>
    )
}