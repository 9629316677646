import { FormComponent } from "../form";
import Joi from "joi";
import { ActionButton } from "../buttons/ActionButton";
import { routesMap } from "../../constants/routes";

import { TextLink } from "../generic/TextLink";
import { TextLine } from "../generic/TextLine";
import { SocialPanel } from "../generic/SocialPanel";
import {Spacer,TCContainer,TextContainerCenter} from "./indexs.style"
import { useFormHandling } from "../../hooks/formUtils";
import { RequestConfigs } from "../../api";

import { FieldType,FieldConfig,TextFieldType } from "../fields";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";







const fields:FieldConfig[] = [
  {
    type:FieldType.text,
    name:"name",
    label:"Name",
    joiSchema:Joi.string().min(4).required(),
    typeOptions:{type:TextFieldType.text}
  },
  {
    type:FieldType.text,
    name:"emailAddress",
    label:"Email",
    joiSchema:Joi.string().email({ tlds: { allow: false } }).required(),
    typeOptions:{type:TextFieldType.text}
  },
  {
    type:FieldType.text,
    name:"password",
    label:"Password",
    joiSchema:Joi.string().min(6).required(),
    typeOptions:{type:TextFieldType.password}
  },

  {
    type:FieldType.checkBox,
    name:"allowTerms",
    label:"terms",
    joiSchema:Joi.boolean().required(), 
    typeOptions:{text:(<TCContainer>I agree to <TextLink hasLine={true} fontSize="12px" route={routesMap.privacyPolicy.to}>privacy policy</TextLink> and <TextLink route={routesMap.tc.to} hasLine={true} fontSize="12px">terms of conditions</TextLink></TCContainer>)}
  },
];

export const Signup = () => {
  const { handleInputChange, handleClick, serverRes } = useFormHandling({
    requestType: RequestConfigs.signup,
  });
  const navigate = useNavigate()

  useEffect(() => {
    if(serverRes?.succeeded){
      navigate(routesMap.signals.to)
    }
  
  }, [serverRes]);



  const subTitle = (
    <TextContainerCenter>
      Already have an account?&nbsp;
      <TextLink hasLine={false} fontSize="16px" route={routesMap.login.to}>
        Log in
      </TextLink>
    </TextContainerCenter>
  );
  return (
    <>
      <FormComponent
        serverRes={serverRes}
        onChange={handleInputChange}
        fields={fields}
        title="Signup"
        subTitle={subTitle}
      ></FormComponent>

      <ActionButton
        onClick={handleClick}
        label="Signup"
        route="#"
        width="93%"
        height="48px"
        fontSize="16px"
      />
      <Spacer />
      <TextLine width="88%">or</TextLine>
      <Spacer />
      <SocialPanel />
      <Spacer />
    </>
  );
};
