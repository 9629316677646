import { useEffect, useState } from "react";
import styled from "styled-components";
import { Colors } from "../../theme/colors";
import React from "react";
import { FormComponent } from "../form";
import Joi from "joi";
import { ApiResponse } from "../../api";
import { useSession,Account } from "../../context/SessionContext";

import { FieldType, FieldConfig, TextFieldType } from "../fields";
import states from 'states-us';
import { Billing } from "../../context/SessionContext";
import { ActionButton ,ButtonStyle} from "../buttons/ActionButton";
import { useFormHandling } from "../../hooks/formUtils";
import { RequestConfigs } from "../../api";

const StatesOptions = states.map(state => ({
  value: state.abbreviation,
  label: `${state.name} (${state.abbreviation})`
}));

const fields: FieldConfig[] = [
  {
    type: FieldType.text,
    name: "password",
    label: "New Password",
    joiSchema: Joi.string().min(4).required(),
    typeOptions: { type: TextFieldType.password }
  },
  
]





export const ResetPassword: React.FC = () => {

  const { handleInputChange, handleClick, serverRes,formValues } = useFormHandling({ requestType:RequestConfigs.resetPassword})

  const createFormComponent = () => {
    
    
    return(
      <>
      <FormComponent fields={fields} title="Personal Info" onChange={handleInputChange} serverRes={serverRes} align="flex-start" />
      <ActionButton onClick={handleClick} label="Change Password" width="auto" height="48px"/>
      </>
      
    ) 
  }





  return (
    <>
      <Container id="main-sig">
        {createFormComponent()}
      </Container>

    </>
  );
};




const Container = styled.div`
display: flex;
flex-direction: column;
flex-wrap: wrap;
justify-content:flex-start;
align-items: flex-start;
width: 100%;
border-radius: 8px;
gap:20px;
`

const Head = styled.div`
display: flex;
flex-wrap: wrap;
justify-content:space-between;
align-items: center;
width: 100%;
`

const Title = styled.h3`
margin-top: -5px;
margin-bottom: 0px;
`

const Text = styled.p`
margin-top: -8px;
margin-bottom: -8px;
font-size: 16px;
color:${Colors.text}

`




