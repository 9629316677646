import { useState } from "react";
import { apiCall } from "../api";
import { ApiResponse } from "../api";
import { useSession } from "../context/SessionContext";
import { useLoader } from "../context/LoaderContext";
import { RequestConfig } from "../api";
import { useNavigate } from "react-router-dom";

interface FormHandlingProps{
  requestType:RequestConfig;
  data?:object;
  params?:object;
  disableNav?:boolean

}

function removeProperty(obj:any, propToRemove:string) {
  // Destructure the property to remove and gather the rest properties into `rest`
  const { [propToRemove]: _, ...rest } = obj;
  // Return the new object without the specified property
  return rest;
}

export const useFormHandling = ({requestType,data={},params={}}:FormHandlingProps) => {
  const { setSession } = useSession();
  const { setLoading } = useLoader();
  const navigate = useNavigate()

  const [formValues, setFormValues] = useState<{ [key: string]: any }>({});
  const [formValidFields, setFormValidFields] = useState<{ [key: string]: boolean }>({});
  const [serverRes, setServerRes] = useState<ApiResponse | null>(null);

  const handleInputChange = (name: string, value: any, valid: boolean) => {
    
    setFormValidFields({ ...formValidFields, [name]: valid });
    if(value==="" || value===null){
      setFormValues((prevForm) => (removeProperty(prevForm,name)));
    } else {
      setFormValues((prevForm) => ({
        ...prevForm,
        [name]: value
    }));
    }
      
  };

  const handleClick = async () => {
    
    
    const validationArr = Object.values(formValidFields);
  
    if ( validationArr.includes(false)) return;
    const responseData = await apiCall({ requestType, data:{...formValues,...data},params:params }, setLoading, setSession,navigate);
    setServerRes(responseData);
  };

  return { handleInputChange, handleClick, serverRes,formValues };
};
