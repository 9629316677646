import { Header} from "./Header";
import { Footer } from "./Footer";
import styled from "styled-components";
import { Backgrounds } from "../../constants/Images";
import { Colors } from "../../theme/colors";

interface LayoutProps {
  children: React.ReactNode;
}

export const AuthPage = ({ children }: LayoutProps) => {
  return (
    <>
    <Header />
    <Body>
        <MainContainer>
           {children}
        </MainContainer>
    </Body>
    <Footer />
    </>
    
  );
};


const Body = styled.div`
  display: flex;
  justify-content: center;
  /* align-items: center; // Center content vertically */
  width: 100%;
  min-height: 80vh; // Minimum height to cover the full viewport height
  background-image: url(${Backgrounds.MainBackground});
  background-size: cover;
  background-position: center; // Ensure the background is centered

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const MainContainer = styled.div`
display: flex;
  justify-content: center;
  flex-direction:column;
  align-items:center;
  margin-top:60px;
  margin-bottom:auto;
  margin-left: 24px;
  margin-right: 24px;
  padding: 32px;
  /* padding-top: 28px; */
  border-radius: 16px;
  background-color:${Colors.whiteBackground};
  width: 100%;
  max-width: 356px; // Fixed width, adjust as needed
`
