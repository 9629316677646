import styled from "styled-components";
import { Colors } from "../../theme/colors";
import { ApiResponse } from "../../api";
import { Icons } from "../../constants/Images";
import { Field,FieldConfig } from "../fields";




interface FormProps {
  fields: FieldConfig[];
  serverRes?: ApiResponse | null
  onChange:(name:string,value:any,valid:boolean)=>void;
  title?: string;
  align?:'flex-start'| 'flex-end'| 'center'
  subTitle?:any;
  showMessageBox?:boolean
}




export const FormComponent = ({ fields, title, subTitle,onChange,serverRes,showMessageBox=true,align='center' }: FormProps) => {

  return (
    <FormContainer>
      
      {title && <Title $align={align}>{title}</Title>}
      {serverRes && showMessageBox &&<MessageBox $align={align}><Icon src={serverRes.succeeded?Icons.Success : Icons.Exclamation}/><Text id="text">{serverRes.Message}</Text></MessageBox>}
      
      {subTitle}
      {
      fields.map((field, index) => {
        return (
        <Field
          key={index}
          fieldConfig={field}
          onChange={onChange}
          serverRes={serverRes}
        />
        )
        
      })
      }
      
    </FormContainer>
  );
};

const FormContainer = styled.div`
display:flex;
flex-direction:column;
align-items: center; /* This will center the items horizontally */
justify-content: center; /* This will center the items vertically and space them evenly */
background-color: ${Colors.whiteBackground};
width: 100%;
margin-bottom: 10px;
`
const Title=styled.h3<{$align:'flex-start'| 'flex-end'| 'center' }>`
margin: 0;
margin-bottom: 10px;
align-self:${prop=>prop.$align};
`

const MessageBox = styled.div<{$align:'flex-start'| 'flex-end'| 'center' }>`
display:flex;
align-items: center;
justify-content: flex-start; /* This will center the items vertically and space them evenly */
background-color: ${Colors.messageBox};
padding: 10px;
align-self:${prop=>prop.$align};
width: 80%;
margin-bottom: 10px;
margin-top: 0px;
border-radius: 8px;
`

const Text = styled.div`
  display:flex;
  width: 100%;
justify-content: center;
`


const Icon = styled.img`
  width: 16px;
  height: 16px;
`;
