import { Header} from "./Header";
import { Footer } from "./Footer";
import styled from "styled-components";
import { useDeviceType } from "../../context/DeviceContext";
import { Backgrounds } from "../../constants/Images";


interface LayoutProps {
  children: React.ReactNode;
}

export const DefaultPage = ({ children }: LayoutProps) => {
  const {isMobile} = useDeviceType()
  return (
    <>
    
      <Header />
    
    

    <Wrapper>
    <Body className="default-body" $isMobile={isMobile}>
    {children}
    </Body>
    </Wrapper>
    
    <Footer />
    </>
    
  );
};


const Wrapper = styled.div`
display: flex;
flex-direction: column;
background-image: url(${Backgrounds.MainBackground});
background-size: cover;
background-position: relative; // Ensure the background is centered
`

const Body = styled.div<{ $isMobile?: boolean }>`
  display: flex;
  justify-content: space-between;
  /* align-items: center; // Center content vertically */
  /* width: 100%; */
  min-height: 80vh; // Minimum height to cover the full viewport height
  margin-left: ${props => props.$isMobile ? 'none' : '120px'};
  margin-right: ${props => props.$isMobile ? 'none' : '120px'};
  margin-top: ${props => props.$isMobile ? 'none' : '28px'};
  margin-bottom: ${props => props.$isMobile ? 'none' : '28px'};
  

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
