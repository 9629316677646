
import Joi from "joi";
import { RequestConfigs } from "./../../api";
import { routesMap } from "./../../constants/routes";
import { Icons } from "./../../constants/Images";
import { Signal } from "./signal";
import { TextLink } from "../generic/TextLink";   
import { DataLayout } from "../data";
import { FieldType,FieldConfig,TextFieldType } from "../fields";
import { useEffect } from "react";
import { Promo } from "./promo";





const fieldsConfig:FieldConfig[] = [
  {
    type: FieldType.text,
    name: "search",
    label: "EURUSD",
    joiSchema: Joi.string().allow(null, ''),
    typeOptions:{type:TextFieldType.search}
  },
  {
    type: FieldType.dateRange,
    name: "createdOn",
    label: "Created on",
    joiSchema: Joi.array(),
    typeOptions:{
      fromFieldName:"from",
      toFieldName:"to",
      defaultPeriod:"Last 7 Days"
    }
  },
  {
    type: FieldType.multiSelect,
    name: "assetType",
    label: "Asset Type",
    joiSchema: Joi.array(),
    typeOptions:{
      options: [
        { name: "Stocks", value: 1 },
        { name: "Forex", value: 2 },
        { name: "Commodities", value: 3 },
        { name: "Crypto", value: 4 },
        { name: "Indexes", value: 5 },
        { name: "Bonds", value: 6 },
      ],
    }
  },

  {
    type: FieldType.multiSelect,
    name: "tradingType",
    label: "Trading Type",
    joiSchema: Joi.array(),
    typeOptions:{
      options: [
        { name: "Day Trade", value: 2 },
        { name: "Swing", value: 3 },
      ],
    }
  },
  {
    type: FieldType.multiSelect,
    name: "direction",
    label: "Direction",
    joiSchema: Joi.array(),
    typeOptions:{
      options: [
        { name: "Buy", value: 1 },
        { name: "Sell", value: 2 },
      ],
    }
  },
]

const registerText = (<>You will need to <TextLink route={routesMap.login.to} fontSize="16px">Login</TextLink> in order to see live signals. Don’t have account yet? <TextLink route={routesMap.signup.to} fontSize="16px">Signup</TextLink> </>)
const notSureText = (<>Not sure? Check out <TextLink route={routesMap.signalsHistory.to}>Historical Performance</TextLink> before you decide</>)

export const Signals = () => {
const fields = [...fieldsConfig]
const isPublic = window.location.pathname===routesMap.signalsHistory.to
if(!isPublic){
  fields.splice(1,1)
}

  return (
    <>
    <DataLayout 
      fields={fields} 
      Element={Signal}
      HeadElement={isPublic?Promo:undefined}
      dataKey="signals" 
      publicPage={routesMap.signalsHistory.to} 
      privatePage={routesMap.liveSignals.to}
      publicName="Signals History"
      privateName="Live Signals"
      publicRequest={RequestConfigs.signalsHistory}
      privateRequest={RequestConfigs.signals}
      registerText={registerText}
      registerTitle="Live Signals"
      notSureText={notSureText}
      subscribeText="You haven't subscribed to live signals yet. Subscribe to get started!"
      icon={Icons.Bars}

    />
    </>
    
  );
};

