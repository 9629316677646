import React, { useState } from 'react'
import styled from 'styled-components'
import { Colors } from '../../theme/colors'
import { ActionButton } from '../buttons/ActionButton'
import { useDeviceType } from '../../context/DeviceContext'
import { FieldConfig, FieldType,Field, TextFieldType } from '../fields'
import { Section,LeftContainerCentered,Title,ExpertContainer,ExpertIcon,FieldContainer,LeftContainer,SymbolName,RightContainer,MainTitle,GreyText,ScrollableContainer,Box,BoldText,BoxSection,ButtonsSection } from './index.style'
import { Line } from '../generic/Line'
import { Images } from '../../constants/Images'


export interface StepBoxProps{
    question:string;
    field:FieldConfig;
    onFieldChange:(name:string,value:any)=>void;
    next:()=>void;
    back:()=>void;
    price:number;

}

export const StepBox: React.FC<StepBoxProps> = ({question,field,onFieldChange,next,back,price}) => {
    
    const {isMobile} = useDeviceType()

    return (
        
            <>
             {/* <ExpertIcon $isMobile={isMobile} src={Images.Avatar1} /> */}
             <ExpertContainer id="expt-cont">
            <Section className="section" >
                        <LeftContainerCentered className="Left">
                            <MainTitle>
                                <SymbolName>{question}</SymbolName>
                            </MainTitle>
                            <FieldContainer $typeOptions={field?.typeOptions}>
                                  <Field fieldConfig={field} onChange={onFieldChange}/>
                            </FieldContainer>

                        </LeftContainerCentered>
                        <RightContainer className="Right">
                            <MainTitle>
                                <SymbolName>${price.toFixed(2)}</SymbolName>
                            </MainTitle>
                        </RightContainer>
                    </Section>
                    <Section className="section">

                    



                    </Section >

                    
                    <>
                        <Line></Line>
                        <ButtonsSection>
                            <ActionButton label="Back" onClick={back}/> 
                            <ActionButton label="Next" onClick={next}/>    

                        </ButtonsSection>
                    </>


                    </ExpertContainer>
            
            </>
           
    )
}


