import { useFormHandling } from "./../../hooks/formUtils";
import styled from "styled-components";
import { RequestConfigs } from "../../api";
import { useDeviceType } from "../../context/DeviceContext";
import { BillingForm } from "./BillingForm";
import { PaymentForm } from "./PaymentForm";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { accountRoutesMap } from "../../constants/routes";
import { ProdcutType } from "../buttons/AddToCartButton";
import { ApiResponse } from "../../api";



export const Pay = () => {
  const [req,setReq] = useState(RequestConfigs.newCardPay)
  const { handleInputChange, handleClick, serverRes,formValues } = useFormHandling({ requestType:req})
  const {isMobile} = useDeviceType()
  const {paySourceId,cardNumber} = formValues
  const location = useLocation()
  const data  = location.state as ApiResponse;
  

  const navigate = useNavigate()

  useEffect(() => {
    if(serverRes?.succeeded){
      let route = "?product="+ProdcutType.memberProgram
      if(serverRes.purchases && serverRes.purchases.length>0){
        const member = serverRes.purchases.filter(purchase=>purchase.productType===ProdcutType.memberProgram)
        if (member.length===0){
          route = "?product="+ serverRes.purchases[0].productType
        } 
        navigate(accountRoutesMap.payThankuPage.to+route)
      } else if (serverRes.redirect){
        navigate(serverRes.redirect)
      }
      
    }
  
  }, [serverRes]);

  useEffect(() => {
    if(data?.succeeded){
      let route = "?product="+ProdcutType.memberProgram
      if(data.purchases && data.purchases.length>0){
        const member = data.purchases.filter(purchase=>purchase.productType===ProdcutType.memberProgram)
        if (member.length===0){
          route = "?product="+ data.purchases[0].productType
        } 
        navigate(accountRoutesMap.payThankuPage.to+route)
      } else if (data.redirect){
        navigate(data.redirect)
      }
      
    }
  
  }, [data]);



  useEffect(() => {
    if(paySourceId){
      setReq(RequestConfigs.existingCard)
    } else if (cardNumber){
      setReq(RequestConfigs.newCardPay)
    } 
  
  }, [paySourceId,cardNumber]);
  
  
  

  return (
    
    <DataLayoutContainer $isMobile={isMobile} className="data-layout">
       
       <DataContainer className="data" $isMobile={isMobile}>
           <Body id="body-container">
           <BillingForm serverRes={serverRes} onChange={handleInputChange}/>
         </Body>
       </DataContainer>
       <RightContainer $isMobile={isMobile} className="right-container">
        <PaymentForm serverRes={data || serverRes} onChange={handleInputChange} onClick={handleClick}/>
       </RightContainer>
      
       
       

  
   </DataLayoutContainer>
   
    
  );
};


const DataLayoutContainer = styled.div<{$isMobile:boolean}>`
display: flex;
flex-direction: row;
flex-wrap: wrap;
gap:20px;
width: 100%;
margin-top: ${props=>props.$isMobile? '20px' : 'none'};;
margin-bottom: ${props=>props.$isMobile? '20px' : 'none'};
/* height: 0; */
`;

const DataContainer = styled.div<{$isMobile:boolean}>`
   flex: 1;
   min-width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-left: ${props=>props.$isMobile? '20px' : 'none'};
  margin-right: ${props=>props.$isMobile? '20px' : 'none'};
`;


const RightContainer = styled.div<{$isMobile:boolean}>`
  display: flex;
  flex-direction: column;
  margin-left: ${props=>props.$isMobile? '20px' : 'none'};
  margin-right: ${props=>props.$isMobile? '20px' : 'none'};
  margin-top: ${props=>props.$isMobile? '20px' : 'none'};
  flex:${props=>props.$isMobile? 1 : '0 0 30%'} ;
  max-width: ${props=>props.$isMobile? 'none' : '320px'};
  min-width: 250px;
  gap:20px;
`;

const Body = styled.div`
 display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  width: 100%;
`;

