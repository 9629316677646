import React from 'react'
import { ThankU } from '../components/registrations/ThankU'
import { AuthPage } from '../components/Layout/AuthPage'
import { routesMap } from '../constants/routes'

export const ForgotPasswordThankUPage: React.FC = () => {
    return (
        <AuthPage>
            <ThankU
                title='Password Reset Email Sent!'
                text='The password reset information has been sent to your Email address.'
                actionText='Back to login page'
                actionUrl={routesMap.login.to}
            />
        </AuthPage>
    )
}