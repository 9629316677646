import styled from "styled-components";
import { Colors } from "../../../theme/colors";
import { Images } from "../../../constants/Images";

export const UnlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  gap: 28px;
  margin: 0 auto;
  position: relative;
  padding-bottom: 180px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding-bottom: 0px;
  }
`;

export const UnlockCheckArea = styled.div`
  display: flex;
  gap: 80px;
  align-items: center;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    flex-flow: column-reverse;
  }
`;

export const UnlockCheckContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  gap: 24px;
`;

export const UnlockCheckList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const CheckItem = styled.div`
  display: flex;
  gap: 6px;
  align-items: flex-start;
`;

export const PlayerContainer = styled.div`
  display: flex;
  gap: 16px;
`;

export const BannerContainer = styled.div`
  display: flex;
  gap: 46px;
  max-width: 1131px;
  width: 100%;
  border-radius: 16px;
  background-color: ${Colors.blackBackground};
  background-image: url(${Images.SmallCurve});
  background-repeat: no-repeat;
  position: absolute;
  bottom: -80px;
  left: 35px;
  padding: 40px 0px;
  justify-content: space-around;
  @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        position: static;
        width: 100%;
    }
`;

export const BannerItem = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
  align-items: center;
  width: 293px;
  justify-content: center;
`;

export const Space = styled.div`
  height: 156px;
  @media screen and (max-width: 768px) {
    height: 0px;
  }
`;

export const ImageContainer = styled.div`
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 382px;
    margin: auto;
    @media screen and (max-width: 1024px) {
        width: 320px;
    }
    @media screen and (max-width: 768px) {
        width: 100%;
    }
`
export const ImgFront = styled.img`
    position: relative;
    z-index: 5;
    width: 300px;
    margin: auto;
    @media screen and (max-width: 1024px) {
        width: 320px;
    }
    @media screen and (max-width: 768px) {
        width: 100%;
    }
`

export const BackgroundCube = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 80%;
    background-color: ${Colors.secondary};
    z-index: 1;
    border-radius: 15px;
`


