
// when filter is true the menu will show filter icon on mobile


import { MetaConfig,metaMap } from "./metaTags";


interface RouteMap{
    [key:string]:RouteConfig
}

interface RouteConfig{
    name:string;
    to:string;
    inMenu:boolean;
    filter:boolean;
    meta?:MetaConfig;
    
}
export const routesMap:RouteMap = {
    login:{ name: "login", to: "/login",inMenu:false,filter:false},
    signup:{ name: "Signup", to: "/signup",inMenu:false,filter:false},
    forgotPassword:{ name: "Forgot Password", to: "/forgot-password",inMenu:false,filter:false},
    thankuPage:{ name: "Forgot Password", to: "/forgot-password/thank-u",inMenu:false,filter:false},
    homePage:{ name: "Homepage", to: "/",inMenu:true,filter:false},
    cart:{ name: "Cart", to: "/cart",inMenu:false,filter:false},
    tc:{ name: "Terms & Conditions", to: "/terms-and-conditions",inMenu:false,filter:false},
    aml:{ name: "AML", to: "/aml",inMenu:false,filter:false},
    privacyPolicy:{ name: "Privacy Policy", to: "/privacy-policy",inMenu:false,filter:false},
    signals:{ name: "Signals", to: "/signals",inMenu:true,filter:false},
    signalsHistory:{ name: "Signals History", to: "/signals/history",inMenu:true,filter:true},
    liveSignals:{ name: "Live Signals", to: "/signals/live",inMenu:true,filter:true},
    experts:{ name: "Experts", to: "/experts",inMenu:true,filter:false},
    expertsMarket:{ name: "Experts Market", to: "/experts/market",inMenu:true,filter:true},
    myExperts:{ name: "My Experts", to: "/experts/my-experts",inMenu:true,filter:true},
    eaBuilder:{ name: "EA Builder", to: "/ea-builder",inMenu:true,filter:false},
    newEaBuild:{ name: "New EA Build", to: "/ea-builder/new",inMenu:true,filter:true},
    myEaBuilds:{ name: "My Builds", to: "/ea-builder/my-builds",inMenu:true,filter:true},
    courses:{ name: "Online courses", to: "/courses",inMenu:false,filter:false},
    coursesMarket:{ name: "Online courses Market", to: "/courses/market",inMenu:false,filter:true},
    myCourses:{ name: "My Online courses", to: "/courses/my-courses",inMenu:false,filter:true},
    faq:{ name: "FAQ", to: "/faq",inMenu:true,filter:false},
}
for(let i in routesMap){
    if(metaMap[i])
        routesMap[i].meta = metaMap[i]

}

export const accountRoutesMap:RouteMap = {
    account:{ name: "Account", to: "/account",inMenu:true,filter:false},
    personalInfo:{ name: "Persoal info", to: "/account/personal-info",inMenu:true,filter:false},
    resetPassword:{ name: "Reset password", to: "/account/reset-password",inMenu:true,filter:false},
    marketingPreference:{ name: "Marketing Preference", to: "/account/marketing-preference",inMenu:true,filter:false},
    membershipProgram:{ name: "Membership program", to: "/account/membership-program",inMenu:true,filter:false},
    signalsPreference:{ name: "Signals Preference", to: "/account/signals-preference",inMenu:true,filter:false},
    pay:{ name: "Pay", to: "/account/pay",inMenu:false,filter:false},
    logout:{ name: "Logout", to: "/logout",inMenu:true,filter:false},
    payThankuPage:{ name: "Payment Success", to: "/account/pay/success",inMenu:false,filter:false},
}

export const paymentsRoutesMap = {
    auth:{ name: "Auth", to: "/processing-3d",inMenu:false,filter:false},
    fingerPrint:{ name: "Finger Print", to: "/finger-print",inMenu:false,filter:false},
    challenge:{ name: "Challenge", to: "/challenge",inMenu:false,filter:false},
}
    
export const baseRoutes = Object.values(routesMap).filter((item)=>item.inMenu)
export const AccountRoutes = Object.values(accountRoutesMap).filter((item)=>item.inMenu)
    

