import { ActiveButton,SwitchContainer,Button } from './index.style';
import { useNavigate } from 'react-router-dom';

interface SwitcherProps{
    btn1Text:string;
    btn1Url?:string;
    btn2Text:string;
    btn2Url?:string;
}

// Component
export const PageSwitcher = ({btn1Text,btn2Text,btn1Url,btn2Url}:SwitcherProps) => {
  const activeButton = window.location.pathname===btn1Url?btn1Text:btn2Text
  const inactiveButton = activeButton===btn1Text?btn2Text:btn1Text
  const url = activeButton===btn1Text?btn2Url:btn1Url
  const navigate = useNavigate()

  const handleClick = (button:any) => {
    if(url)
      navigate(url)
    }
  

  return (
    <SwitchContainer>
      
      {
      activeButton===btn1Text &&
      <>
      <ActiveButton>{activeButton}</ActiveButton>
      <Button onClick={() => handleClick('btn2')}>{inactiveButton}</Button> 
      </>
      }

{
      activeButton===btn2Text &&
      <>
      <Button onClick={() => handleClick('btn2')}>{inactiveButton}</Button> 
      <ActiveButton>{activeButton}</ActiveButton>
      </>
      }
     
    </SwitchContainer>
  );
}


