

import React from 'react'
import { DefaultPage } from '../components/Layout/DefaultPage'
import { Account } from '../components/account'

export const AccountPage: React.FC = () => {
    return (
        <DefaultPage>
            <Account />
        </DefaultPage>
    )
}