
import { Line } from "../../generic/Line";
import { ActionButton,ButtonStyle } from "../../buttons/ActionButton";
import { useDeviceType } from "../../../context/DeviceContext";
import styled from "styled-components";
import { Icons } from "../../../constants/Images";
import { Colors } from "../../../theme/colors";
import { Backgrounds } from "../../../constants/Images";
import { useSession } from "../../../context/SessionContext";
import { useFormHandling } from "../../../hooks/formUtils";
import { RequestConfigs } from "../../../api";
import { useEffect, useState } from "react";
import { FormComponent } from "../../form";
import { Svg } from "../../data/index.style";


export const Member = () => {
  const {isMobile} = useDeviceType()
  const {session} = useSession()

  const isMember = session.account?.isMember
  const isSubscribed = session.account?.isSubscribed
  
  // const [isSubscribed,setIsSubscribed] = useState<boolean>(!(!session.account?.isSubscribed))

  const { handleInputChange, handleClick, serverRes,formValues } = useFormHandling({ requestType:RequestConfigs.updateMembership})

  const changeSubscription = ()=>{
    // setIsSubscribed(!isSubscribed)
    handleInputChange("enabled",!isSubscribed,true)
  }

  useEffect(() => {
    if(formValues && formValues.hasOwnProperty("enabled")){
      handleClick()
    } 

  }, [formValues]);

  const getActionButton = ()=>{
    if(isSubscribed && isMember){
     return  <ActionButton onClick={changeSubscription} label="Cancel My Subscription"  style={ButtonStyle.red} fontSize="14px" fontWeight="500"></ActionButton>
    } else {
      return <ActionButton onClick={changeSubscription} label="Reactivate" style={ButtonStyle.dark} fontSize="14px" fontWeight="500"></ActionButton>
    }

  }

  
  return (
    
      <Container>
        <FormComponent fields={[]} title="Membership" onChange={()=>{}} serverRes={serverRes} align="flex-start" />
        
          <Svg as={Icons.Member} width="100px" />
          <TextContainer>
            <Text>Your membership is Active</Text>
            {!isSubscribed && 
              <Text>till <b>2024-04-11</b> and will not be renewed</Text>
            }
            
          </TextContainer>
          
        
          <Line/>
          <Buttons>
            {getActionButton()}
            
          </Buttons>    
        
      </Container>
  
  );
};


const Text = styled.p`
font-size: 16px;
`




const TextContainer = styled.div`
  display: flex;
  gap:5px;
  width: 100%;
  align-items: center;
  justify-content: center;
`;



const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-top: 10px;
`;


const Icon = styled.img`
`
const Buttons=styled.div`
display: flex;
justify-content: flex-end;
width: 100%;
margin-top: 15px;
gap:10px;
`









