
import {Spacer} from "./indexs.style"
import { TextLink } from "../generic/TextLink";
import styled from "styled-components";
import { Icons } from "../../constants/Images";
import { Colors } from "../../theme/colors";


interface ThankUProps {
  title:string;
  text:string;
  actionText:string;
  actionUrl:string;

}

export const ThankU = ({title,text,actionText,actionUrl}:ThankUProps) => {




  return (
    <>
    <Container>
    <Icon src={Icons.Vsuccess} />
    <Title>{title}</Title>
    <Text>{text}</Text>
    <Spacer />
    <TextLink route={actionUrl} fontSize="16px">{actionText}</TextLink>
      <Spacer />
    </Container>
      
    </>
  );
};


const Icon = styled.img`
width: 160px;
width: 160px;
`

const Title = styled.h3``

const Text = styled.div`
color: ${Colors.text};
font-size: 14px;
`

const Container = styled.div`
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
`