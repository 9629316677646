interface Map {
  [key: string]: any;
}


export const mt4Indicators = [
  {
    "name": "Moving Average (MA)",
    "description": "Smooths price data for trend identification."
  },
  {
    "name": "Bollinger Bands (BB)",
    "description": "Measures market volatility and price levels."
  },
  {
    "name": "Parabolic SAR (PSAR)",
    "description": "Identifies potential reversals in market trends."
  },
  {
    "name": "Average Directional Movement Index (ADX)",
    "description": "Measures trend strength without direction."
  },
  {
    "name": "Ichimoku Kinko Hyo",
    "description": "Displays support, resistance, and trend direction."
  },
  {
    "name": "Relative Strength Index (RSI)",
    "description": "Measures speed and change of price movements."
  },
  {
    "name": "Stochastic Oscillator",
    "description": "Identifies overbought or oversold conditions."
  },
  {
    "name": "Moving Average Convergence Divergence (MACD)",
    "description": "Shows relationship between two moving averages."
  },
  {
    "name": "Commodity Channel Index (CCI)",
    "description": "Identifies cyclical trends in commodities."
  },
  {
    "name": "Average True Range (ATR)",
    "description": "Measures market volatility."
  },
  {
    "name": "Momentum",
    "description": "Measures the speed of price movement."
  },
  {
    "name": "Accumulation/Distribution (A/D)",
    "description": "Shows supply and demand by volume and price."
  },
  {
    "name": "Money Flow Index (MFI)",
    "description": "Measures the intensity of money flowing in and out."
  },
  {
    "name": "On-Balance Volume (OBV)",
    "description": "Relates volume to price change direction."
  },
  {
    "name": "Volumes",
    "description": "Tracks the number of trades over time."
  },
  {
    "name": "Alligator",
    "description": "Uses smoothed moving averages for trend identification."
  },
  {
    "name": "Awesome Oscillator (AO)",
    "description": "Measures market momentum."
  },
  {
    "name": "Fractals",
    "description": "Identifies potential market turning points."
  },
  {
    "name": "Gator Oscillator",
    "description": "Visualizes the Alligator indicator's phases."
  },
  {
    "name": "Market Facilitation Index (MFI)",
    "description": "Measures the market's willingness to move prices."
  },
  {
    "name": "Fibonacci Retracement",
    "description": "Identifies potential support and resistance levels."
  },
  {
    "name": "ZigZag",
    "description": "Filters out insignificant price movements."
  },
  {
    "name": "Linear Regression",
    "description": "Displays the trend direction and strength."
  },
  {
    "name": "Envelopes",
    "description": "Creates a channel for price movement analysis."
  },
  {
    "name": "Standard Deviation",
    "description": "Measures the dispersion of price values."
  }
]


export const mt5Indicators = [
  {
    "name": "Moving Average (MA)",
    "description": "Smooths price data for trend identification."
  },
  {
    "name": "Bollinger Bands (BB)",
    "description": "Measures market volatility and price levels."
  },
  {
    "name": "Parabolic SAR (PSAR)",
    "description": "Identifies potential reversals in market trends."
  },
  {
    "name": "Average Directional Movement Index (ADX)",
    "description": "Measures trend strength without direction."
  },
  {
    "name": "Ichimoku Kinko Hyo",
    "description": "Displays support, resistance, and trend direction."
  },
  {
    "name": "Standard Deviation",
    "description": "Measures the dispersion of price values."
  },
  {
    "name": "Envelopes",
    "description": "Creates a channel for price movement analysis."
  },
  {
    "name": "Relative Strength Index (RSI)",
    "description": "Measures speed and change of price movements."
  },
  {
    "name": "Stochastic Oscillator",
    "description": "Identifies overbought or oversold conditions."
  },
  {
    "name": "Moving Average Convergence Divergence (MACD)",
    "description": "Shows relationship between two moving averages."
  },
  {
    "name": "Commodity Channel Index (CCI)",
    "description": "Identifies cyclical trends in commodities."
  },
  {
    "name": "Average True Range (ATR)",
    "description": "Measures market volatility."
  },
  {
    "name": "Momentum",
    "description": "Measures the speed of price movement."
  },
  {
    "name": "DeMarker",
    "description": "Evaluates the demand of the underlying asset."
  },
  {
    "name": "Force Index",
    "description": "Combines price movement and volume."
  },
  {
    "name": "Gator Oscillator",
    "description": "Visualizes the Alligator indicator's phases."
  },
  {
    "name": "Market Facilitation Index (MFI)",
    "description": "Measures the market's willingness to move prices."
  },
  {
    "name": "Awesome Oscillator (AO)",
    "description": "Measures market momentum."
  },
  {
    "name": "Accumulation/Distribution (A/D)",
    "description": "Shows supply and demand by volume and price."
  },
  {
    "name": "Money Flow Index (MFI)",
    "description": "Measures the intensity of money flowing in and out."
  },
  {
    "name": "On-Balance Volume (OBV)",
    "description": "Relates volume to price change direction."
  },
  {
    "name": "Volumes",
    "description": "Tracks the number of trades over time."
  },
  {
    "name": "Alligator",
    "description": "Uses smoothed moving averages for trend identification."
  },
  {
    "name": "Fractals",
    "description": "Identifies potential market turning points."
  },
  {
    "name": "ZigZag",
    "description": "Filters out insignificant price movements."
  },
  {
    "name": "Linear Regression",
    "description": "Displays the trend direction and strength."
  },
  {
    "name": "Fibonacci Retracement",
    "description": "Identifies potential support and resistance levels."
  },
  {
    "name": "Fibonacci Arcs",
    "description": "Displays potential support and resistance arcs."
  },
  {
    "name": "Fibonacci Fan",
    "description": "Shows potential support and resistance lines."
  },
  {
    "name": "Fibonacci Time Zones",
    "description": "Identifies potential reversal points in time."
  },
  {
    "name": "Gann Fan",
    "description": "Uses angles to forecast support and resistance."
  },
  {
    "name": "Gann Grid",
    "description": "Shows potential price movements using a grid."
  },
  {
    "name": "Andrews' Pitchfork",
    "description": "Helps identify trend channels."
  },
  {
    "name": "Cycle Lines",
    "description": "Identifies cyclical patterns in price movement."
  },
  {
    "name": "Bears Power",
    "description": "Measures the strength of bears in the market."
  },
  {
    "name": "Bulls Power",
    "description": "Measures the strength of bulls in the market."
  },
  {
    "name": "Double Exponential Moving Average (DEMA)",
    "description": "Reduces lag in moving averages."
  },
  {
    "name": "Triple Exponential Moving Average (TEMA)",
    "description": "Further reduces lag in moving averages."
  },
  {
    "name": "Triple Exponential Moving Average Oscillator (TRIX)",
    "description": "Combines triple exponential moving average with momentum."
  }
]


export const tradingTypeOption = [
  {
    name: "Scalping",
    value: 1
  },
  {
    name: "Day Trading",
    value: 2
  },
  {
    name: "Swing",
    value: 3
  },
]



export const assetTypeOption = [
  {
    name: "Stocks",
    value: 1
  },
  {
    name: "Forex",
    value: 2
  },
  {
    name: "Commodities",
    value: 3
  },
  {
    name: "Crypto",
    value: 4
  },
  {
    name: "Indexes",
    value: 5
  },
  {
    name: "Bonds",
    value: 6
  },
]



export const platformTypeOption = [
  {
    name: "MT4",
    value: 1
  },
  {
    name: "MT5",
    value: 2
  }
]

export const directionOption = [
  {
    name: "Buy",
    value: 1
  },
  {
    name: "Sell",
    value: 2
  }
]


export const MT5_INDICATORS: Map = {
  "Moving Average (MA)": 0,
  "Bollinger Bands (BB)": 1,
  "Parabolic SAR (PSAR)": 2,
  "Average Directional Movement Index (ADX)": 3,
  "Ichimoku Kinko Hyo": 4,
  "Standard Deviation": 5,
  "Envelopes": 6,
  "Relative Strength Index (RSI)": 7,
  "Stochastic Oscillator": 8,
  "Moving Average Convergence Divergence (MACD)": 9,
  "Commodity Channel Index (CCI)": 10,
  "Average True Range (ATR)": 11,
  "Momentum": 12,
  "DeMarker": 13,
  "Force Index": 14,
  "Gator Oscillator": 15,
  "Market Facilitation Index (MFI)": 16,
  "Awesome Oscillator (AO)": 17,
  "Accumulation/Distribution (A/D)": 18,
  "Money Flow Index (MFI)": 19,
  "On-Balance Volume (OBV)": 20,
  "Volumes": 21,
  "Alligator": 22,
  "Fractals": 23,
  "ZigZag": 24,
  "Linear Regression": 25,
  "Fibonacci Retracement": 26,
  "Fibonacci Arcs": 27,
  "Fibonacci Fan": 28,
  "Fibonacci Time Zones": 29,
  "Gann Fan": 30,
  "Gann Grid": 31,
  "Andrews' Pitchfork": 32,
  "Cycle Lines": 33,
  "Bears Power": 34,
  "Bulls Power": 35,
  "Double Exponential Moving Average (DEMA)": 36,
  "Triple Exponential Moving Average (TEMA)": 37,
  "Triple Exponential Moving Average Oscillator (TRIX)": 38
}
export const MT4_INDICATORS: Map = {
  "Moving Average (MA)": 0,
  "Bollinger Bands (BB)": 1,
  "Parabolic SAR (PSAR)": 2,
  "Average Directional Movement Index (ADX)": 3,
  "Ichimoku Kinko Hyo": 4,
  "Relative Strength Index (RSI)": 5,
  "Stochastic Oscillator": 6,
  "Moving Average Convergence Divergence (MACD)": 7,
  "Commodity Channel Index (CCI)": 8,
  "Average True Range (ATR)": 9,
  "Momentum": 10,
  "Accumulation/Distribution (A/D)": 11,
  "Money Flow Index (MFI)": 12,
  "On-Balance Volume (OBV)": 13,
  "Volumes": 14,
  "Alligator": 15,
  "Awesome Oscillator (AO)": 16,
  "Fractals": 17,
  "Gator Oscillator": 18,
  "Market Facilitation Index (MFI)": 19,
  "Fibonacci Retracement": 20,
  "ZigZag": 21,
  "Linear Regression": 22,
  "Envelopes": 23,
  "Standard Deviation": 24
}


export const DEV_STATUS: Map = {
  1: "New",
  2: "In Review",
  3: "In Progress",
  4: "Ready"
}

