import React, { createContext, useContext, useEffect, useState } from 'react';
import { Dictionary,apiCall,RequestConfigs } from '../api';
import { useSession,SessionData } from './SessionContext';
import { useLoader } from './LoaderContext';
import fs from "fs"
import path from "path"
import en from "../dictionary/en.json"



interface DictionaryContextType {
  dictionary?: Dictionary;
  translate: (key: string) => string;
}

const DictionaryContext = createContext<DictionaryContextType | undefined>(undefined);


const getDictionary = async (
                              setDictionary:React.Dispatch<React.SetStateAction<Dictionary>>,
                              setGlobalLoading: React.Dispatch<React.SetStateAction<boolean>>,
                              setSession: React.Dispatch<React.SetStateAction<SessionData>>,
                            )=>{

  const responseData = await apiCall({ requestType:RequestConfigs.dictionary}, setGlobalLoading, setSession);
  if(responseData?.dictionary){
    setDictionary(responseData?.dictionary || {})
  }
  

}


export const useDictionary = (): DictionaryContextType => {
  const context = useContext(DictionaryContext);
  if (!context) {
    throw new Error('useDictionaryType must be used within a DictionaryProvider');
  }
  return context;
};

interface DictionaryProviderProps {
  children: React.ReactNode;
}

export const DictionaryProvider: React.FC<DictionaryProviderProps> = ({ children }) => {
  const [dictionary, setDictionary] = useState<Dictionary>({});
  
  

  const {setSession} = useSession()
  const {setLoading} = useLoader()
  



  useEffect(() => {
    setDictionary(en)
    getDictionary(setDictionary,setLoading,setSession)
  }, []);

  const translate = (key: string)=>{
    return dictionary[key] || key
  }



  const contextValue: DictionaryContextType = {
    dictionary,
    translate

  };

  return (
    <DictionaryContext.Provider value={contextValue}>
      {children}
    </DictionaryContext.Provider>
  );
};
