import styled from "styled-components"
import { Colors } from "../../theme/colors"
import { TextFieldType } from "../fields"


export const BulletPoint = styled.ul`
display: flex;
flex-wrap: wrap;
justify-content: flex-start;
gap: 5px 20px;
padding: 0;
color: ${Colors.text};
`
export const Point = styled.li`
margin-left: 10px;
padding: 0;`

export const Value = styled.div`
display: flex;
gap:10px;
`


export const Container = styled.div`
display: flex;
/* flex-direction: column; */
flex-wrap: wrap;
justify-content:center;
align-items: flex-start;
border: 1px solid ${Colors.fieldBorder};
background-color: ${Colors.whiteBackground};
padding: 20px;
border-radius: 8px;
gap:20px;
`

export const ChartContainer = styled.div`

    justify-content: flex-start;
    align-content: flex-start;
`



export const Section = styled.div`
display: flex;
justify-content: space-between;
gap:20px;
width: 100%;
`

export const ScrollableContainer = styled.div`
  overflow-x: auto;
`;

export const BoxSection = styled.div<{ $isMobile: boolean }>`
  display: flex;
  padding-bottom: 20px;
  flex: 0 0 auto; 
  gap: 0px 20px;
  margin-right:${(prop => prop.$isMobile ? '-40px' : 'none')} ;
`;

export const Box = styled.div`
  flex: 0 0 128px; 
  border-radius: 8px;
  padding: 12px;
  background-color: ${Colors.messageBox};

  
  &:last-child {
    margin-right: 0; /* Remove margin from the last box */
  }
`;

export const SectionLeft = styled.div`
display: flex;
justify-content: flex-start;
gap:20px;
`

export const ButtonsSection = styled.div`
display: flex;
justify-content: space-between;
gap:10px;
`




export const LeftContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
gap:10px;
`

export const LeftContainerCentered = styled.div`
display: flex;
flex-direction: column;
align-items: center;
flex:1;
gap:10px;
`


export const RightContainer = styled.div`
padding: 5px;
display: flex;
gap:5px;
`

export const Title = styled.div`
display: flex;
flex-direction: column;
/* gap:20px; */
align-items: flex-start;
`


export const SymbolName = styled.div`
`

export const BoldText = styled.div`
font-weight: 600;
font-size: 14px;
margin-top: 5px;
margin-bottom: 5px;

`
export const GreyText = styled.div`
font-size: 14px;
color: ${Colors.text};
`
export const MainTitle = styled.div`
display: flex;
justify-content: center;
gap:5px;
color: ${Colors.blackText};
font-size: 18px;
font-weight: 600;
`

export const SubTitle = styled.div`
color: ${Colors.blackText};
font-size: 14px;
font-weight: 600;
`





export const ExpertContainer = styled.div`
flex: 1;
display: flex;
flex-direction: column;
justify-content: flex-start;
gap:10px;
/* margin-right: 10px; */
`




export const ExpertIcon = styled.img<{ $isMobile: boolean }>`
box-shadow: 0 0 23px  ${Colors.shadow};
/* border-radius: 50% */
border-radius: 8px;
width: ${(prop => prop.$isMobile ? '100%' : '140px')};
height: ${(prop => prop.$isMobile ? '120px' : '140px')};
object-fit: cover;
margin:0;
`

export const DirectionIcon = styled.img`
width: 16px;
`

export const FieldContainer = styled.div<{$typeOptions?:any}>`
display: flex;
justify-content: center;
/* align-items: center; */
width: ${props=>props.$typeOptions?.type===TextFieldType.textArea?"100%":"auto"};
`
