import { useEffect, useState } from "react";
import styled from "styled-components";
import { Colors } from "../../theme/colors";
import React from "react";
import { FormComponent } from "../form";
import Joi from "joi";
import { ApiResponse } from "../../api";
import { useSession } from "../../context/SessionContext";

import { FieldType, FieldConfig, TextFieldType } from "../fields";
import states from 'states-us';
import { Billing } from "../../context/SessionContext";
import { ActionButton ,ButtonStyle} from "../buttons/ActionButton";

const StatesOptions = states.map(state => ({
  value: state.abbreviation,
  label: `${state.name} (${state.abbreviation})`
}));

const fields: FieldConfig[] = [
  {
    type: FieldType.text,
    name: "name",
    label: "Full Name",
    joiSchema: Joi.string().min(4).required(),
    typeOptions: { type: TextFieldType.text }
  },
  {
    type: FieldType.phone,
    name: "phoneNumber",
    label: "Mobile Phone",
    joiSchema: Joi.string().min(5).max(13).required(),
    typeOptions: { countryFieldName: "country" }
  },
  {
    type: FieldType.select,
    name: "state",
    label: "State",
    joiSchema: Joi.string().max(30),
    typeOptions: { options: StatesOptions },
    hide:true
  },

  {
    type: FieldType.text,
    name: "city",
    label: "City",
    joiSchema: Joi.string().max(30).required(),
    typeOptions: { type: TextFieldType.text }
  },
  {
    type: FieldType.text,
    name: "address1",
    label: "Address",
    joiSchema: Joi.string().max(50).required(),
    typeOptions: { type: TextFieldType.text }
  },
  {
    type: FieldType.text,
    name: "postalCode",
    label: "Postal Code",
    joiSchema: Joi.string().max(50).required(),
    typeOptions: { type: TextFieldType.text }
  },

]



interface BillingProps {
  serverRes?: ApiResponse | null
  onChange: (name: string, value: any, valid: boolean) => void;
}

export const BillingForm: React.FC<BillingProps> = ({ serverRes, onChange }) => {

  const { session } = useSession()

  const [isEditMode,setIsEditMode] = useState(!session.billing?.city)

  const billing = session.billing as Billing


  useEffect(() => {
    for (let name in billing) {
        const value = (billing as any)[name];
        if(value)
          onChange(name,value,true)
    }
}, [billing]);


  const checkCountry = (name: string, value: any, valid: boolean) =>{
    if(name==="country" && value==="us" ){
      fields[2].hide=false
    } else if(name==="country"){
      fields[2].hide=true
    }
    onChange(name,value,valid)

  }


 
  
  


  const createFormComponent = () => {
    
    for (let i in fields) {
      const fieldName = fields[i].name as keyof Billing;
      if (billing.hasOwnProperty(fieldName) && billing[fieldName]) {
        fields[i].defaultValue = billing[fieldName];
      }
      
    }
    return <FormComponent fields={fields} title="Billing Information" onChange={checkCountry} serverRes={serverRes} align="flex-start" />
  }





  return (
    <>
      <Container id="main-sig">

        {!isEditMode &&
         <>
          <Head>
          <Title>Billing Information</Title>
          <ActionButton onClick={()=>{setIsEditMode(true)}} label="Edit" style={ButtonStyle.light}/>
        </Head>
        <Text>{billing.name}</Text>
          <Text>{billing.phoneNumber}</Text>
          <Text>{billing.country}, {billing.state + ","} {billing.address1} {billing.address1}, </Text>
          <Text>10013</Text>
         </>
         
        }
        
       
        {isEditMode && createFormComponent()}
      </Container>

    </>
  );
};




const Container = styled.div`
background-color: ${Colors.whiteBackground};
display: flex;
flex-direction: column;
flex-wrap: wrap;
justify-content:flex-start;
align-items: flex-start;
border: 1px solid ${Colors.fieldBorder};
padding: 20px;
border-radius: 8px;
gap:20px;
`

const Head = styled.div`
display: flex;
flex-wrap: wrap;
justify-content:space-between;
align-items: center;
width: 100%;
`

const Title = styled.h3`
margin-top: -5px;
margin-bottom: 0px;
`

const Text = styled.p`
margin-top: -8px;
margin-bottom: -8px;
font-size: 16px;
color:${Colors.text}

`




