import React from 'react'
import { useSession } from '../context/SessionContext'
import { routesMap } from '../constants/routes'
import { useNavigate } from 'react-router-dom'

export const CoursesPage: React.FC = () => {
    const navigate = useNavigate()
    const {session} = useSession()
   
    React.useEffect(() => {
        if(session.isLogged){
            navigate(routesMap.myCourses.to)
        } else {
            navigate(routesMap.coursesMarket.to)
        }
    
      }, []);

    
    return (
    
        <></>
    
    )
}