import React from 'react';
import styled from 'styled-components';
import { Colors } from '../../theme/colors'; // Ensure this path is correct and Colors.text is defined


// Styled component for the line with text
const LineWithText = styled.div<{width?:string}>`
  display: flex;
  align-items: center;
  color: ${Colors.text}; // Make sure Colors.text is a valid color string
  width: ${(props)=>props.width || "100%"}; // Corrected from "100%" to 100%
  flex: 1;
  border-bottom: 1px solid ${Colors.fieldBorder};
`;

// React component with TypeScript
export const Line: React.FC = () => (
  <LineWithText>
  </LineWithText>
);
