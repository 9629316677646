import { useFormHandling } from "../../hooks/formUtils";
import { RequestConfigs } from "../../api";
import { ActionButton,ButtonStyle } from "./ActionButton";



export enum ProdcutType {
  experts = 1,
  courses = 2,
  memberProgram=3
}

interface RemoveFromCartButtonProps {
  location:number
}



export const RemoveFromCartButton = ({location } : RemoveFromCartButtonProps) => {

  const {handleClick} = useFormHandling({requestType:RequestConfigs.removeFromCart,data:{location}})

  return (
    <ActionButton
      label={"Remove"}
      width="110px"
      height="36px"
      fontSize="14px"
      fontWeight="500"
      style={ButtonStyle.light}
      onClick={handleClick}
    />
  );
};

