import * as React from "react";
import { useFormHandling } from "./../../hooks/formUtils";
import styled from "styled-components";
import { RequestConfigs } from "../../api";
import { useDeviceType } from "../../context/DeviceContext";
import { CartItem as CartComp } from "./CartItem";
import { useSession } from "./../../context/SessionContext";
import { PromoForm } from "./Promo";
import { Summary } from "./Summary";
import { StickySummary } from "./StickySummary";
import { EmptyCart } from "./EmptyCart";
import { MembershipPromo } from "./MembershipPromo";



export const Cart = () => {
  const {session} = useSession()
  const { handleClick, formValues } = useFormHandling({ requestType:RequestConfigs.checkout})
  const {isMobile} = useDeviceType()
  const data = session.cart
  const hasMembership = session.account?.isMember || data.filter(cartItem=>cartItem.type===3).length>0
  
  React.useEffect(() => {
    handleClick()

  }, [formValues]);




  return (
    <>
    <DataLayoutContainer className="data-layout">
       
        
       <RightContainer $isMobile={isMobile} className="right-container">
           <PromoForm/>
         {!isMobile &&
           <Summary/>
         }
         
       </RightContainer>
       <DataContainer className="data" $isMobile={isMobile}>
           <Body id="body-container">
           <>
           {data.length===0 &&
            <EmptyCart></EmptyCart>

           }
           {
           data && data.map((item,index) => (
             <CartComp data={item} index={index}/>

           ))}

           { !hasMembership && data.length>0 &&
            <MembershipPromo/>
           }
           

           </>
           
         </Body>
         
       </DataContainer>
       
       

  
   </DataLayoutContainer>
   {isMobile &&
           <StickySummary/>
         }
    </>
    
  );
};


const DataLayoutContainer = styled.div`
display: flex;
flex-direction: row-reverse;
flex-wrap: wrap;
gap:20px;
width: 100%;
height: 0;
`;

const DataContainer = styled.div<{$isMobile:boolean}>`
   flex: 1;
   min-width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-left: ${props=>props.$isMobile? '20px' : 'none'};
  margin-right: ${props=>props.$isMobile? '20px' : 'none'};
`;


const RightContainer = styled.div<{$isMobile:boolean}>`
  display: flex;
  flex-direction: column;
  margin-left: ${props=>props.$isMobile? '20px' : 'none'};
  margin-right: ${props=>props.$isMobile? '20px' : 'none'};
  margin-top: ${props=>props.$isMobile? '20px' : 'none'};
  flex:${props=>props.$isMobile? 1 : '0 0 30%'} ;
  max-width: ${props=>props.$isMobile? 'none' : '320px'};
  min-width: 250px;
  gap:20px;
`;

const Body = styled.div`
 display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  width: 100%;
`;

