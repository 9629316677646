import React from "react";

interface AuthProps {
  methodURL: string | null;
  threeDSMethodData: string | null;
}

export const FingerPrintIframe: React.FC<AuthProps> = ({ methodURL,threeDSMethodData }) => {
  // Construct the HTML content for the iframe
  const iframeHtml = `
    <html>
    <body>
        <form name="sendFingerprint" action="${methodURL}" method="post">
            <input name="threeDSMethodData" value="${threeDSMethodData}" />
            <input type="submit" style="display:none;" />
        </form>
        <script type="text/javascript">
            setTimeout(function() {
                document.forms['sendFingerprint'].submit();
            }, 100);
        </script>
    </body>
    </html>
  `;

  return (
    <iframe 
      id="finger-print-iframe" 
      style={{ display: "none" }} 
      srcDoc={iframeHtml} 
      title="Authentication Frame"
    />
  );
};
