import React from 'react'
import { ThankU } from '../components/registrations/ThankU'
import { AuthPage } from '../components/Layout/AuthPage'

import { useLocation } from 'react-router-dom'
import { ProdcutType } from '../components/buttons/AddToCartButton'
import { accountRoutesMap,routesMap } from '../constants/routes'

export const PaymentThankUPage: React.FC = () => {

    const location = useLocation();

    // Extract the value of the 'product' parameter from the query string
    const queryParams = new URLSearchParams(location.search);
    const product = Number(queryParams.get('product'));

    let route =routesMap.signals


    switch (product) {
        case ProdcutType.courses:
            route=routesMap.courses
            break;
        case ProdcutType.memberProgram:  
            route=routesMap.signals
            break;
        case ProdcutType.experts:  
            route=routesMap.experts
            break;
        case ProdcutType.expertBuilder:  
            route=routesMap.eaBuilder
            break;
    }




    return (
        <AuthPage>
            <ThankU
                title='Payment Successful!'
                text='Thank you! Your payment has been received, and your order is confirmed.'
                actionText={`Go to ${route.name}`}
                actionUrl={route.to}
            />
        </AuthPage>
    )
}