import  { useState } from "react";
import styled from "styled-components";
import { Icons, Logos } from "../../../../constants/Images";
import {  useLocation } from "react-router-dom";
import { Colors } from "../../../../constants/theme";
import { Sidebar } from "./mobileMenu";
import { baseRoutes } from "../../../../constants/routes";
import { useFilter } from "../../../../context/FilterContext";
import { useEffect } from "react";
import { Dimensions } from "../../../../theme/style";


export const MobileHeader = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const {setShowFilter} = useFilter()

  const { pathname } = useLocation();
  const [isHome, setIsHome] = useState(true);
  
  useEffect(() => {
    pathname === "/" ? setIsHome(true) : setIsHome(false);
  },[pathname]);



  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const openFilter = () => {
    setShowFilter(true)
  };


  const location = useLocation();
  const title= baseRoutes.filter(r=>r.to===location.pathname)[0]?.name
  const filter= baseRoutes.filter(r=>r.to===location.pathname)[0]?.filter
  return (
    <Header title={title}>
      <BurgerWrapper onClick={toggleSidebar}>
        <IconImg src={isHome?Icons.BurgerLight:Icons.Burger} alt="burger"  />
      </BurgerWrapper>
      <TitleWrapper>
      {title && title !== 'Homepage' ? (
          <Title>{title}</Title>
        ) : (
          <LogoImg src={isHome?Logos.DarkLogo:Logos.Logo} alt="Logo" />
        )}
      </TitleWrapper>
      {filter &&
        <FilterWrapper onClick={openFilter}>
          <IconImg src={Icons.Filter} alt="filter"  />
        </FilterWrapper>
      }
      
      <Sidebar isOpen={isSidebarOpen} onClose={toggleSidebar} /> {/* Sidebar component */}
    </Header>
  );
};

const Header = styled.header<{title: string}>`
  position: relative; /* Ensure relative positioning for absolute positioning of Logo */
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  height: 64px;
  background-color: ${ props => props.title !== 'Homepage' ? Colors.whiteBackground : Colors.blackBackground};
  box-shadow: ${ props => props.title !== 'Homepage' ? `0px 4px 4px 0px ${Colors.shadow}` : ''};
  font-size: 16px;
  font-weight: 700;
  color: ${Colors.text};
`;

const BurgerWrapper = styled.div`
  position: absolute; /* Absolute positioning for Burger */
  left: 15px; /* Adjust as needed */
  z-index:100;
`;

const FilterWrapper = styled.div`
  position: absolute; /* Absolute positioning for Burger */
  right: 15px; /* Adjust as needed */
  z-index:100;
`;

const IconImg = styled.img`
  width: 36px;
  aspect-ratio: 1;
  object-fit: contain;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const LogoImg = styled.img`
  width: ${Dimensions.mobileLogoWidth}px;
  object-fit: contain;
`;

const Title = styled.div`
`;

