import React, { useEffect } from 'react'
import Router from './Router'
import { RequestConfigs, apiCall } from '../api'
import { useSession } from '../context/SessionContext'
import { useLoader } from '../context/LoaderContext'

interface MainProps {
    token?:string
}


const Main: React.FC<MainProps> = ({token}) => {
    const { setSession } = useSession();
  const { setLoading } = useLoader();
    useEffect(() => {
        if(token){
            apiCall({requestType:RequestConfigs.registerFcm,data:{token}},setLoading,setSession)
        }
      }, [token]);
    
    
    return (
        <>
            <Router />
        </>
    )
}

export default Main