import { Suspense, useEffect, useState } from "react";
import Main from "./pages/Main";
import { SessionProvider } from "./context/SessionContext";
import './App.css';
import { LoaderProvider } from "./context/LoaderContext";
import { DeviceProvider } from "./context/DeviceContext";
import { FilterProvider } from "./context/FilterContext";
import { DictionaryProvider } from "./context/DictionaryContext";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { getToken, messaging } from "./modules/fcm";

function App() {
  const [token, setToken] = useState<string>();

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker.register('/firebase-messaging-sw.js')
      });
    }

    const getFCMToken = async () => {
      console.log("getFCMToken",process.env.REACT_APP_VAP_ID_KEY)
      try {
        const currentToken = await getToken(messaging, { vapidKey: process.env.REACT_APP_VAP_ID_KEY });
        if (currentToken) {
          setToken(currentToken);
        } 
      } catch (err) {
        console.log('An error occurred while retrieving token. ', err);
      }
    };

    if ('Notification' in window) {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          getFCMToken();
        }
      });
    }

  }, []);

  return (
    <SessionProvider>
      <DeviceProvider>
        <FilterProvider>
          <LoaderProvider>
            <DictionaryProvider>
              <Suspense fallback={<>Loading</>}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <Main token={token} />
                </LocalizationProvider>
              </Suspense>
            </DictionaryProvider>
          </LoaderProvider>
        </FilterProvider>
      </DeviceProvider>
    </SessionProvider>
  );
}

export default App;
