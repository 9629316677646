import React from "react";
import { ImageContainer } from "./index.style";

interface ImageProps {
  paddingtop?: number;
  width?: number | string;
  height?: number | string;
  src: string;
}

export const Image: React.FC<ImageProps> = (props) => {
  const { paddingtop, width, height, src } = props;
  return (
    <ImageContainer
      paddingtop={paddingtop}
      width={width}
      height={height}
      src={src}
    />
  );
};
