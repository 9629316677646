import { baseRoutes,AccountRoutes } from "../constants/routes";

export interface ParentRouteItem {
    name: string;
    to: string;
    page?:any;
    children?:BaseRoute[]
  }

  export interface BaseRoute {
    name: string;
    to: string;
    page?:any;
  }



export const buildRoutes = (baseRoutes:BaseRoute[])=>{
    const routes:ParentRouteItem[] = []
    for(let i in baseRoutes){
        const route = baseRoutes[i]
        const childrens = baseRoutes.filter((r:BaseRoute) => r.to.includes(route.to) && r.to!==route.to && route.to!=="/" );
        const routeArr =route.to.split("/") 
        routeArr.pop()
        const fatherPath = routeArr.join("/")
        const isChild = baseRoutes.filter((r:BaseRoute) => r.to===fatherPath).length>0;

        if(!isChild){
            const r:ParentRouteItem = {
                name: route.name,
                to: route.to,

            }
            if (childrens.length>0){
                r.children=childrens
            }
            routes.push(r)
        }

    }
    return routes
}
export const RoutesSet = buildRoutes(baseRoutes)

export const MemberRoutesSet = buildRoutes(baseRoutes.concat(AccountRoutes))

