

import React from 'react'
import { DefaultPage } from '../components/Layout/DefaultPage'
import { Signals } from '../components/signals'

export const SignalsHistoryPage: React.FC = () => {
    return (
        <DefaultPage>
            <Signals />
        </DefaultPage>
    )
}