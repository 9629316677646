import React, { createContext, useContext, useEffect, useState } from 'react';
import { ExpertBuildConfig } from '../components/eaBuilder/newBuildNew';
import { SignalsPreference } from '../api';

export interface Account {
  customerId: number;
  name: string;
  isMember: boolean;
  isSubscribed: boolean;
  emailAddress:string;
  photoURL:string;
  internalCommunication:CommunicationPreferences;
  signalsPreference:SignalsPreference
}

export interface CommunicationPreferences {
  emails: {
    marketing: boolean;
    tradingInfo: boolean;
  };
  sms: {
    marketing: boolean;
    tradingInfo: boolean;
  };
  push: {
    marketing: boolean;
    tradingInfo: boolean;
  };
}

export interface Billing {
  name:string | null;
  phoneNumber:string | null
  country:string | null
  state:string | null
  city:string | null
  address1:string | null
  postalCode:string | null
}

export interface PaySource{
  paySourceId:number
  IsPrimary:string
  paySourceType:string
  cardType:string
  cardLast4:string
}

export interface CartItem {
  id: number;
  name:string;
  type: number;
  typeName: string;
  price: number;
  currency: string;
  crmProductId: number;
  previewImage:string;
}

export interface Client {
  device: number;
  ip: string;
  language: string;
  country: string; //iso capital

}

export interface SessionData {
  isLogged: boolean;
  client:Client;
  cart:CartItem[];
  customExpert:ExpertBuildConfig | null
  account?: Account | null;
  billing?: Billing;
  paySources?: PaySource[];
  nextScreen:string | null
}

// const defaultAccount = {
//   customerId: 123,
//   name: "my name",
//   isMember: true,
//   isSubscribed: true,
//   emailAddress:"myEmail@gmail.com",
//   photoURL:"https://static.vecteezy.com/system/resources/thumbnails/027/951/137/small_2x/stylish-spectacles-guy-3d-avatar-character-illustrations-png.png"
// }

const defaultSessionData: Partial<SessionData> = {
  isLogged: false,
  account: null,
  nextScreen:null,
  cart: [],
};

type SessionContextType = {
  session: SessionData;
  setSession: React.Dispatch<React.SetStateAction<SessionData>>;
};

const SessionContext = createContext<SessionContextType | undefined>(undefined);

interface SessionProviderProps {
  children: React.ReactNode;
}

export const SessionProvider: React.FC<SessionProviderProps> = ({ children }) => {
  const [session, setSession] = useState<SessionData>(() => {
    // Try to retrieve session data from local storage
    const storedSession = localStorage.getItem('session');
    return storedSession ? JSON.parse(storedSession) : defaultSessionData as SessionData;
  });

  useEffect(() => {
    // Save session data to local storage whenever it changes
    localStorage.setItem('session', JSON.stringify(session));
  }, [session]);

  return (
    <SessionContext.Provider value={{ session, setSession }}>
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = () => {
  const context = useContext(SessionContext);
  if (!context) {
    throw new Error('useSession must be used within a SessionProvider');
  }
  return context;
};
