import { Header} from "./Header";
import { Footer } from "./Footer";
import styled from "styled-components";
import { useDeviceType } from "../../context/DeviceContext";
import { Colors } from "../../theme/colors";
import { Backgrounds } from "../../constants/Images";


interface LayoutProps {
  children: React.ReactNode;
  title:string;
  subTitle?:string;

}

export const ContentPage = ({ children,title,subTitle }: LayoutProps) => {
  const {isMobile} = useDeviceType()
  return (
    <>
    <Header />
    <Body $isMobile={isMobile}>
    <TitleContainer>
      <Title>
          {title}
      </Title>
      {subTitle&&
      <SubTitle>
      {subTitle}
    </SubTitle>

      }
      
      
    </TitleContainer>
    
    {children}
    </Body>
    <Footer />
    </>
    
  );
};


const Body = styled.div<{ $isMobile?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: center; // Center content vertically */
  /* width: 100%; */
  min-height: 80vh; // Minimum height to cover the full viewport height
  margin-left: ${props => props.$isMobile ? 'none' : '120px'};
  margin-right: ${props => props.$isMobile ? 'none' : '120px'};
  margin-top: ${props => props.$isMobile ? 'none' : '28px'};
  margin-bottom: ${props => props.$isMobile ? 'none' : '28px'};

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 160px;
  width: 100%;
  /* font-size: 24px;
  font-weight: 500; */
  color: ${Colors.blackText};
  /* align-items: center; // Center content vertically */
  /* width: 100%; */
  
  background-image: url(${Backgrounds.MainBackground});
  background-size: cover;
  background-position: center; // Ensure the background is centered
`;

const Title = styled.h2`
`
const SubTitle = styled.h3`
`
