import React from 'react';
import styled from 'styled-components';
import { Icons } from '../../constants/Images';
import { Colors } from '../../theme/colors';



interface PayButtonProps {
  name:string,
  icon:any
}
export const PayButton: React.FC<PayButtonProps> = ({name,icon}) => {
 

  return (
    <SocialLoginContainer>
        <SocialButton id="social">
            <Icon src={icon}/>
            <Text>{name}</Text>
            <ArrowIcon src={Icons.RightVector}/>
        </SocialButton>
        
    </SocialLoginContainer>
  );
};


// Styled container for the social login buttons
const SocialLoginContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  width: 100%;
  height: 48px;
`;

const Icon = styled.img`
width: 24px;
width: 24px;
`

const ArrowIcon = styled.img`
width: 10.82px;
width: 6%.23;
`

const Text = styled.div`
font-size: 16px;
color: ${Colors.blackText};
margin: 0;
padding: 0;
`

// Styled button for social logins
const SocialButton = styled.div`
  
  border: 1px solid ${Colors.fieldBorder};;
  background-color: transparent;
  outline: none;
  box-shadow: none;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  justify-content: space-between;
`;
