import { UnlockContainer } from "../index.style";
import { Icons, Images } from "../../../constants/Images";
import { Colors } from "../../../theme/colors";
import { KTSpan, KTText } from "../../Text";
import { Image } from "../../Image";
import { ArrowButton } from "../../buttons/ArrowButton";
import {
  BackgroundCube,
  BannerContainer,
  BannerItem,
  CheckItem,
  ImageContainer,
  ImgFront,
  PlayerContainer,
  UnlockCheckArea,
  UnlockCheckContainer,
  UnlockCheckList,
  UnlockWrapper,
} from "./index.style";
import { useDictionary } from "../../../context/DictionaryContext";
import { Bullets } from "../../generic/Bullets";



export const UnlockTrading = () => {
  const {translate} = useDictionary()
  return (
    <UnlockContainer>
      <UnlockWrapper>
        <KTText fontSize={24} fontWeight={500} lineheight={28}>
          Unlock Exclusive Trading Advantages with Our Membership Program
        </KTText>

        <KTText
          fontSize={16}
          lineheight={22}
          fontWeight={400}
          color={Colors.gray}
        >
          {translate("membership_p1")}
        </KTText>
        <UnlockCheckArea>
          <UnlockCheckContainer>
            <KTText fontSize={18} lineheight={22} fontWeight={500}>
              Membership Benefits
            </KTText>
            <Bullets rows={[translate("membership_benefit1"),translate("membership_benefit2")]} fontSize="16px"/>
            <KTText
              fontSize={16}
              lineheight={22}
              fontWeight={400}
              color={Colors.gray}
            >
             {translate("membership_p2")}
            </KTText>
            <ArrowButton
              label="Join Now"
              iconUrl={Icons.RightArrow}
              iconWidth={10}
              iconHeight={10}
              background={` ${Colors.primary}`}
              color={` ${Colors.whiteBackground}`}
              borderradius={8}
              width={"104px"}
              height={44}
              fontSize={14}
              fontWeight={400}
            />
            <PlayerContainer>
              <Image src={Images.GooglePlay} width={137} height={40} />
              <Image src={Images.AppStore} width={137} height={40} />
            </PlayerContainer>
          </UnlockCheckContainer>
          <ImageContainer>
            <BackgroundCube/>
            <ImgFront src={Images.App} width={"80%"} height={"100%"} />
          </ImageContainer>
        </UnlockCheckArea>
        <BannerContainer>
          <BannerItem>
            <KTText
              fontSize={48}
              lineheight={60}
              fontWeight={700}
              color={`${Colors.whiteBackground}`}
            >
              100+
            </KTText>
            <KTText
              fontSize={16}
              lineheight={24}
              fontWeight={500}
              color={`${Colors.whiteBackground}`}
            >
              Daily Signals
            </KTText>
          </BannerItem>
          <BannerItem>
            <KTText
              fontSize={48}
              lineheight={60}
              fontWeight={700}
              color={`${Colors.whiteBackground}`}
            >
              {translate("winning_signals")}
            </KTText>
            <KTText
              fontSize={16}
              lineheight={24}
              fontWeight={500}
              color={`${Colors.whiteBackground}`}
            >
              Winning Signals
            </KTText>
          </BannerItem>
          <BannerItem>
            <KTText
              fontSize={48}
              lineheight={60}
              fontWeight={700}
              color={`${Colors.whiteBackground}`}
            >
              500+
            </KTText>
            <KTText
              fontSize={16}
              lineheight={24}
              fontWeight={500}
              color={`${Colors.whiteBackground}`}
            >
              Symbols
            </KTText>
          </BannerItem>
        </BannerContainer>
      </UnlockWrapper>
    </UnlockContainer>
  );
};
