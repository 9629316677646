import React from "react";
import styled from "styled-components";
import { Icons,Images,Logos } from "../../../constants/Images";
import { LogoIcon } from "../index.style";
import { NavLink } from "react-router-dom";
import { routesMap } from "../../../constants/routes";
import { Colors } from "../../../theme/colors";
import { useDictionary } from "../../../context/DictionaryContext";

interface LogoProps {
  src: string;
  alt: string;
}

const Logo: React.FC<LogoProps> = ({ src, alt }) => {
  return <LogoIcon src={src} alt={alt} />;
};



interface ContactItemProps {
  icon: string;
  text: string;
}

const ContactItem: React.FC<ContactItemProps> = ({ icon, text }) => {
  return (
    <ContactItemWrapper>
      <ContactIcon src={icon} alt={`${text} icon`} />
      <ContactText>{text}</ContactText>
    </ContactItemWrapper>
  );
};

const ContactItemWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  width:200px;
  margin-top:15px;
`;

const ContactIcon = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  width: 20px;
`;

const ContactText = styled.div`
  font-family: Roboto, sans-serif;
`;

const Footer: React.FC = () => {
  const {translate} = useDictionary()
  return (
    <FooterWrapper>
      <FooterContent>
        <FooterTop>
          <CompanyInfo>
            <CompanyLogo>
              <Logo src={Logos.DarkLogo} alt={`${process.env.REACT_APP_BRAND_NAME} logo`} />
            </CompanyLogo>
            <Disclaimer>
              The information provided on this website, including but not limited to trading robots, and trading signals, is for educational and informational purposes only and should not be construed as financial advice. {process.env.REACT_APP_COMPANY_NAME} does not guarantee the accuracy, completeness, or timeliness of the information provided. Trading involves substantial risk and is not suitable for every investor. You should carefully consider your financial situation and consult with a qualified financial advisor before making any investment decisions.
            </Disclaimer>
            <Disclaimer>
            By using this website, you acknowledge and agree that {process.env.REACT_APP_COMPANY_NAME} is not responsible for any losses or damages resulting from your trading activities. The performance of any trading system or methodology is not necessarily indicative of future results. Past performance is not a guarantee of future returns.
            </Disclaimer>
            <Disclaimer>
            {process.env.REACT_APP_COMPANY_NAME} reserves the right to make changes to the content of this website at any time without notice. By continuing to use this website, you agree to comply with and be bound by the terms and conditions outlined in our policies, including our Anti-Money Laundering <DisclaimerLink to={routesMap.aml.to} >(AML) Policy</DisclaimerLink>.
            </Disclaimer>
            <Disclaimer></Disclaimer>
            <SocialContainer>
                <SocialBadge as={Logos.Facebook} />
                <SocialBadge as={Logos.Instagram}/>
                <SocialBadge as={Logos.Twitter}/>
            </SocialContainer>
            
          </CompanyInfo>
          <FooterLinks>
            <LinkSection>
              <LinkSectionTitle>Company</LinkSectionTitle>
              <LinkSectionItem to={routesMap.experts.to}>Experts</LinkSectionItem>
              <LinkSectionItem to={routesMap.eaBuilder.to}>EA Builder</LinkSectionItem>
              <LinkSectionItem to={routesMap.signals.to}>Signals</LinkSectionItem>
              <LinkSectionItem to={routesMap.faq.to}>FAQ</LinkSectionItem>
            </LinkSection>
            <LinkSection>
              <LinkSectionTitle>Contact Us</LinkSectionTitle>
              <ContactItem icon={Icons.Phone} text={`${process.env.REACT_APP_PHONE}`} />
              <ContactItem icon={Icons.Mail} text={`support@${process.env.REACT_APP_DOMAIN_NAME}`} />
            </LinkSection>
          </FooterLinks>
        </FooterTop>
        <Divider />
        <FooterBottom>
          <Copyright>
            © Copyright 2010 - 2020, All Rights Reserved by {process.env.REACT_APP_BRAND_NAME}
          </Copyright>
          <LegalLinks>
            <LegalLink to={routesMap.tc.to}>Terms & Conditions</LegalLink>
            <LegalLink to={routesMap.privacyPolicy.to}>Privacy Policy</LegalLink>
          </LegalLinks>
        </FooterBottom>
      </FooterContent>
    </FooterWrapper>
  );
};

const FooterWrapper = styled.footer`
  background-color: var(--Black---100, #12141d);
  padding: 56px 60px 29px;

  @media (max-width: 991px) {
    padding: 40px 20px;
  }
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const FooterTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 30px;

  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const CompanyInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const CompanyLogo = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
  font-size: 28px;
  color: ${Colors.primary};
  font-weight: 700;
  text-transform: uppercase;
`;



const Disclaimer = styled.p`
  color: #b9b3b3;
  margin-top: 32px;
  margin-bottom: -15px;
  font: 400 16px/22px Roboto, sans-serif;
`;

const SocialBadge = styled.svg`
  width: 36px;
  margin-top: 24px;
  fill: ${Colors.primary};
`;

const SocialContainer = styled.div`
    gap: 12px;
    display: flex;
`;

const FooterLinks = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 991px) {
    flex-direction: column;
  }
`;

const LinkSection = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: #b9b3b3;
  font-weight: 400;
  line-height: 100%;
  width:150px;
  /* margin-left:50px; */
`;



const LinkSectionTitle = styled.h3`
  color: var(--White---100, #fafafa);
  font: 700 18px/130.7% Roboto, sans-serif;
  margin-bottom: 0px;
`;

const LinkSectionItem = styled(NavLink)`
  font-family: Roboto, sans-serif;
  margin-top: 20px;
  color: #b9b3b3;
  text-decoration: none;
  font-size: 16px;
`;

const DisclaimerLink = styled(NavLink)`
  font-family: Roboto, sans-serif;
  margin-top: 20px;
  color: #b9b3b3;
  /* text-decoration: none; */
  font-size: 16px;
`;

const LegalLink = styled(NavLink)`
  font-family: Roboto, sans-serif;
  margin-top: 20px;
  color: #b9b3b3;
  text-decoration: none;
`;

const Divider = styled.hr`
  border: none;
  border-top: 1px solid rgba(138, 138, 138, 1);
  background-color: #8a8a8a;
  margin: 55px 0;

  @media (max-width: 991px) {
    margin: 40px 0;
  }
`;

const FooterBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  font-size: 14px;
  color: var(--White---100, #fafafa);
  font-weight: 400;
  text-align: center;
  line-height: 143%;

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Copyright = styled.div`
  font-family: Roboto, sans-serif;
  color:#b9b3b3;
`;

const LegalLinks = styled.div`
  display: flex;
  align-self: center;
  gap: 20px;
`;



export {Footer} ;