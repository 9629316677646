import React, { useState } from 'react';
import styled from 'styled-components';
import { PaySource } from '../../context/SessionContext';
import { Colors } from '../../theme/colors';
import { TextLink } from '../generic/TextLink';
import { Icons } from '../../constants/Images';

// Styled components for list and items



interface CardsProps{
  sources?:PaySource[];
  onChange:(paymentId:number | null)=>void;
  enabled:boolean;
}

// Payment Source List Component
export const CardsList = ( {sources=[],enabled,onChange}:CardsProps) => {

  const primary = enabled? sources.filter(source=>source.IsPrimary)[0].paySourceId : null
  
  const [selectedId, setSelectedId] = useState<number | null>(primary);
  
  
  
  const updateBull = (paymentId:number | null)=>{
    
    setSelectedId(paymentId)
    onChange(paymentId)
  }

  const getCardImage = (cardType:any) =>{
    switch (cardType){
      case "TESTCARD":
        return Icons.Visa;
      case "MASTERCARD":
        return Icons.Mastercard
      case "VISA":
        return Icons.Visa
      case "AMEX":
        return Icons.Amex

    }
  }

  React.useEffect(() => {
    if(primary){
      onChange(primary)
    }
}, [primary]);

  return (
    <List>
      {sources.map((source) => (
        <Item
          key={source.paySourceId}
          isSelected={selectedId === source.paySourceId}
          onClick={() => updateBull(source.paySourceId)}
        >
          <Container>
            <Bullet
              type="radio"
              checked={enabled && selectedId === source.paySourceId}
              // onChange={() => updateBull(source.paySourceId)}
            />
            
            <Icon src={getCardImage(source.cardType)}/>
            <Digits>
            ****{source.cardLast4}
            </Digits>
                  
            </Container>
            <Container>
            <TextLink route='/'>Remove</TextLink>
            </Container>
          
        </Item>
      ))}
    </List>
  );
};

const List = styled.div`
 
  background-color: transparent;
  outline: none;
  box-shadow: none;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  cursor: pointer;
  justify-content: space-between;
  margin-top:10px;
  margin-bottom:20px;
  
  
`;

const Item = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height : 48px;
  border: 1px solid ${Colors.fieldBorder};;
  /* margin:0; */
  border-radius: 8px;
  width: 100%;
  /* padding-left: 10px;
  padding-right: 10px; */

  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;



const Icon = styled.img`
width: 40px;


`

const Bullet = styled.input`
`
const Container = styled.div`
display: flex;
justify-content: center;
align-items: center;
padding-left: 10px;
padding-right: 10px;
`

const Digits = styled.div`
margin-left: 10px;

`

