import React, { useEffect, useRef, useState } from 'react';
import styled from "styled-components";
import { Colors } from "../../theme/colors";
import { Bullets } from "../generic/Bullets";
import { ImageBrowse } from '../generic/ImageBrowse';
import { useFormHandling } from '../../hooks/formUtils';
import { ExpertInfo, RequestConfigs } from '../../api';

interface PopupProps{
    setIsVisible:(visible:boolean)=>void
    id:number;

}



export const Popup: React.FC<PopupProps> = ({setIsVisible,id}) => {
    const requestType = {...RequestConfigs.expertInfo,url:RequestConfigs.expertInfo.url+"/"+id}
    const {handleClick,serverRes} = useFormHandling({requestType})

    
    const scrollRef = useRef<HTMLDivElement>(null);
    const [expertInfo, setExpertInfo] = useState<ExpertInfo>();
    const [isImageBrowseOpen, setIsImageBrowseOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    
    
    const openPopup = (index:number) => {
        setCurrentIndex(index);
        setIsImageBrowseOpen(true);
      };


      useEffect(() => {
        
        handleClick()
      }, []);

      useEffect(() => {
        if(serverRes){
            setExpertInfo(serverRes.expertInfo)
        }
        
      }, [serverRes]);

    
 

    const handleScroll = (direction:string) => {
        if (scrollRef.current) {
            const { scrollLeft, clientWidth } = scrollRef.current;
            const scrollAmount = clientWidth / 2; // Scrolls half the width of the container
            const newScrollPosition = direction === 'left' ? scrollLeft - scrollAmount : scrollLeft + scrollAmount;
            scrollRef.current.scrollTo({ left: newScrollPosition, behavior: 'smooth' });
        }
    };

    return ( 
    
    <PopupContainer>

        <PopupContent className="VideoPopupContent">
          <CloseButton onClick={() => setIsVisible(false)}>✖</CloseButton>
            <Title>{expertInfo?.title}</Title>
            <Description>
                {expertInfo?.description}
            </Description>

            <PTitle>
                {expertInfo?.p1Title}
            </PTitle>
            <PText>
                {expertInfo?.p1Text}
            </PText>
            
            {expertInfo?.p1Bullets&&
                <Bullets
                rows={expertInfo?.p1Bullets}
                />  
            }

            <PTitle>
                {expertInfo?.p2Title}
            </PTitle>
            <PText>
                {expertInfo?.p2Text}
            </PText>
            
            {expertInfo?.p2Bullets&&
                <Bullets
                rows={expertInfo?.p2Bullets}
                />  
            }
        
        <ScrollContainerWithButtons>
        <ScrollButton onClick={() => handleScroll('left')}>◀</ScrollButton>
        <ScrollableContainer ref={scrollRef}>
            
          <BoxSection>
            {expertInfo?.images?.map((image, index) => (
                
                <Img 
                src={process.env.REACT_APP_API_ENDPOINT+image} 
                onClick={()=>openPopup(index)}
                />
            
            ))}
          </BoxSection>
          {isImageBrowseOpen && expertInfo?.images && (
                <ImageBrowse images={expertInfo?.images} startIndex={currentIndex} setIsVisible={setIsImageBrowseOpen} />
            )}
        </ScrollableContainer>
        <ScrollButton onClick={() => handleScroll('right')}>▶</ScrollButton>
      </ScrollContainerWithButtons>
            
            
        </PopupContent>

      </PopupContainer>)
}



const Img = styled.img`
height: 150px;
`

const CloseButton = styled.button`
    position: absolute;
    top: 2px;
    right: 2px;
    background-color: transparent;
    border: none;
    color: ${Colors.primary};
    font-size: 20px;
    cursor: pointer;
  `;



  const Title = styled.h3`
  
  `

const PTitle = styled.h4`
  
`

const Description = styled.p`
  
`

const PText = styled.p`
  
`


const BoxSection = styled.div`
  display: flex;
  padding-bottom: 20px;
  flex: 0 0 auto; 
  gap: 0px 20px;
  margin-right:none ;
`;

const Box = styled.div`
  flex: 0 0 128px; 
  border-radius: 8px;
  padding: 12px;
  background-color: ${Colors.messageBox};

  
  &:last-child {
    margin-right: 0; /* Remove margin from the last box */
  }
`;




const ScrollContainerWithButtons = styled.div`
  position: relative;
  width: 100%; // Ensures the container fills the parent
  display: flex;
  justify-content: space-between; // Spreads the child elements across the container
  align-items: center;
  margin-top: 50px;
`;






const PopupContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${Colors.darkShadow};
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    overflow: auto; /* Allow overflow to make the content scrollable */
`;

const PopupContent = styled.div`
  padding: 25px;
  border-radius: 8px;
  background:${Colors.whiteBackground};
  position: relative;
  width: 100%;
  max-width: 1000px; /* Set a maximum width if needed */
  min-height: 500px;
  max-height: 90vh; /* Limit the height of the popup */
  overflow-y: auto; /* Make the content inside the popup scrollable */
`;

const ScrollableContainer = styled.div`
  overflow-x: auto; 
  scrollbar-width: none; 
  -ms-overflow-style: none; 
  flex-grow: 1; // Takes up available space between buttons
  padding: 0 50px; // Adjust this padding based on the size of your buttons
  display: flex; /* Ensure the container's children are displayed in a row */
  align-items: center; /* Center align the children vertically */

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ScrollButton = styled.button`
  min-width: 40px; // Ensure buttons have a fixed width
  height: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: ${Colors.whiteBackground};
  color: ${Colors.text};
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  z-index: 1000;
  font-size: 20px;
  &:hover {
    color: ${Colors.primary};
  }

  &:first-child {
    left: 0; // Left button at the start
  }

  &:last-child {
    right: 0; // Right button at the end
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;
