import { FormComponent } from "../form";
import Joi from "joi";
import { ActionButton } from "../buttons/ActionButton";
import { routesMap } from "../../constants/routes";
import {TextContainerCenter,Spacer} from "./indexs.style"
import { TextLink } from "../generic/TextLink";
import { FieldType,FieldConfig,TextFieldType } from "../fields";
import { useFormHandling } from "../../hooks/formUtils";
import { RequestConfigs } from "../../api";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";




const fields:FieldConfig[] = [
 
  {
    type:FieldType.text,
    name:"emailAddress",
    label:"Email",
    joiSchema:Joi.string().email({ tlds: { allow: false } }).required(),
    typeOptions:{type:TextFieldType.text}
  },
];

export const ForgotPassword = () => {

  const {handleInputChange,handleClick,serverRes}=useFormHandling({requestType:RequestConfigs.forgotPassword})
  const navigate = useNavigate()

  useEffect(() => {
    if(serverRes?.succeeded){
      navigate(routesMap.thankuPage.to)
    }
  
  }, [serverRes]);

  
  return (
    <>
      <FormComponent
        onChange={handleInputChange}
        fields={fields}
        title="Forgot Password"
      ></FormComponent>
      <ActionButton
        label="Reset Password"
        onClick={handleClick}
        route={routesMap.thankuPage.to}
        width="93%"
        height="48px"
        fontSize="16px"
      />
      <Spacer />
      <TextContainerCenter>
        <TextLink route={routesMap.login.to} fontSize="16px">
          Back to login page
        </TextLink>
      </TextContainerCenter>
      <Spacer />
    </>
  );
};
