import * as React from "react";
import styled from "styled-components";
import { Icons, Logos } from "../../../../constants/Images";
import {
  NavLink,
  useLocation,
} from "react-router-dom";
import { Colors } from "../../../../constants/theme";
import { LogoIcon } from "../../index.style";
import { RoutesSet } from "../../../../modules/routesHelper";
import { ActionButton } from "../../../buttons/ActionButton";
import { routesMap } from "../../../../constants/routes";
import { useSession } from "../../../../context/SessionContext";
import { AvatarBtn } from "../../../buttons/avatarBtn";
import { useEffect, useState } from "react";
import { AccountMenu } from "./accountMenu";
import { Dimensions } from "../../../../theme/style";



interface NavItemProps {
  to: string;
  children: React.ReactNode;
}

const NavItem = ({ to, children }: NavItemProps) => {
  const { pathname } = useLocation();
  const [isHome, setIsHome] = useState(true);
  
  useEffect(() => {
    pathname === "/" ? setIsHome(true) : setIsHome(false);
  },[pathname]);

  return (
    <NavItemWrapper>
      <StyledNavLink $isHome={isHome} to={to}>
        {children}{" "}
      </StyledNavLink>
    </NavItemWrapper>
  );
};

const navItems = RoutesSet.filter((r) => r.to !== "/");

export const DesktopHeader = () => {
  const { pathname } = useLocation();
  const [isHome, setIsHome] = useState(true);

  const [isAccMenuOpen, setIsAccMenuOpen] = useState(false);
  const emptyCart = isHome?Icons.CartDark:Icons.Cart
  const fullCart = isHome?Icons.FullCartDark:Icons.FullCart



  const openMenu = () => {
    if (!isAccMenuOpen) setIsAccMenuOpen(true);
  };

  const closeMenu = () => {
    
    if (isAccMenuOpen) setIsAccMenuOpen(false);
  };

  useEffect(() => {
    pathname === "/" ? setIsHome(true) : setIsHome(false);
  },[pathname]);

  const { session } = useSession();

  const generateUserAction = () => {
    
    if (session.account) {
      return (
        <UserActionButtons>
          <ProfileLink onClick={openMenu}>
          <AvatarBtn />
          <AccountMenuBtn src={isHome?Icons.ProfileArrowDark:Icons.ProfileArrow} onClick={openMenu} />
          </ProfileLink>
          
        </UserActionButtons>
      );
    } else {
      return (
        <UserActionButtons>
          <LoginButton $isHome={isHome} href={routesMap.login.to}>
            Login
          </LoginButton>
          <ActionButton label="Signup" route={routesMap.signup.to} />
        </UserActionButtons>
      );
    }
  };

  return (
    <HeaderWrapper $isHome={isHome}>
      <HeaderContent>
        <HomePageLink to={routesMap.homePage.to}>
          <LogoIcon
            src={isHome ? Logos.DarkLogo : Logos.Logo}
            alt={`${process.env.REACT_APP_BRAND_NAME} Logo`}
          />
        </HomePageLink>

        <Nav>
          {navItems.map((item, index) => (
            <NavItem key={index} to={item.to}>
              {item.name}
            </NavItem>
          ))}
        </Nav>
        <UserActions>
          <CartNavLink to={routesMap.cart.to}>
            <CartIcon src={session.cart.length>0?fullCart:emptyCart} alt="Cart Icon" />
          </CartNavLink>

          {generateUserAction()}
        </UserActions>
        <AccountMenu isOpen={isAccMenuOpen} onClose={closeMenu}></AccountMenu>
      </HeaderContent>
    </HeaderWrapper>
  );
};



const HeaderWrapper = styled.header<{ $isHome: boolean }>`
  /* box-shadow: 0px 20px 5px 0px ${Colors.shadow}; */
  box-shadow: ${(props) => props.$isHome ? `none` : `0px 2px 2px ${Colors.darkShadow}`};
  /* z-index: 150; */
  position: relative;
  z-index: 10; /* Ensure the header is on top */
  /* margin-bottom: 50px; */
  box-sizing: border-box;
  /* border-bottom: solid 1px ${Colors.fieldBorder}; */
  background-color: ${(props) => props.$isHome ? `${Colors.blackBackground}` : `${Colors.whiteBackground}`};
  font-weight: 500;
  
  display: flex;
  /* width: 100%; */
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  padding-left: 120px;
  padding-right: 120px;
`;
const AccountMenuBtn = styled.img`
  width: 8px;
  height: 4.27px;
  cursor: pointer;
`;

const HeaderContent = styled.div`
  display: flex;
  width: 100%;
  /* max-width: 1200px; */
  gap: 20px;
  justify-content: space-between;

  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Nav = styled.nav`
  display: flex;
  gap: 32px;
  font-size: 14px;
  color: ${Colors.text};
  font-weight: 500;
  text-align: center;
  line-height: 20px;
  justify-content: flex-start;
  flex-grow: 1;
  flex-basis: auto;
  margin: ${Dimensions.menuMargin}px 0px 0px 0px;
  padding: 2px 40px;
`;

const NavItemWrapper = styled.span``;

const ProfileLink = styled.div`
display: flex;
justify-content: center;
align-items: center;
gap:5px;

`;


const StyledNavLink = styled(NavLink)<{ $isHome: boolean }>`
  color: ${(props) =>
    props.$isHome ? `${Colors.whiteBackground}` : `${Colors.text}`};
  text-decoration: none;
  position: relative;

  &.active,
  &:hover {
    color: ${Colors.primary};
  }

  &.active::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 100%;
    height: 3px;
    background-color: ${Colors.primary};
  }
`;


const UserActions = styled.div`
  display: flex;
  gap: 8px;
  font-size: 14px;
  white-space: nowrap;
  text-align: center;
  line-height: 143%;

  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const CartNavLink = styled(NavLink)`
  display: flex;
`;

const CartIcon = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 36px;
`;

const UserActionButtons = styled.div`
  display: flex;
  align-items: center; /* Align items vertically center */
  padding-left: 16px;
  gap: 8px;
  justify-content: flex-end; /* Align items horizontally to the end */
  flex-grow: 1; /* This will push the buttons to the end of the container */

  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const LoginButton = styled.a<{ $isHome: boolean }>`
  text-decoration: none;
  color: ${(props) =>
    props.$isHome ? `${Colors.whiteBackground}` : `${Colors.text}`};
  margin-right: 10px;
  background: none;
  font-weight: 500;
  font-size: 14px;
  font-family: Roboto, sans-serif;
  border: none;
  cursor: pointer;
`;

// const HomePageLink = styled.a`
//   display: flex;
//   justify-content: center;
//   margin: 0;
// `;

const HomePageLink = styled(NavLink)`
   display: flex;
  justify-content: center;
  margin: 0;
`;

