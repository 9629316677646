
import { Line } from "../generic/Line";
import { ActionButton,ButtonStyle } from "../buttons/ActionButton";
import { useDeviceType } from "../../context/DeviceContext";
import styled from "styled-components";
import { Colors } from "../../theme/colors";
import { Icons } from "../../constants/Images";
import { routesMap } from "../../constants/routes";
import { Svg } from "../data/index.style";


export const EmptyCart = () => {
  const {isMobile} = useDeviceType()

  
  return (
    <DataContainer id="data-container" $isMobile={isMobile}>        
      <Body>
        <SubscribeContainer>
          <Svg as={Icons.EmptyCartIcon} width="100px" />
          <Text>Your cart is Empty</Text>
        
          <Line/>
          <Buttons>
            <ActionButton route={routesMap.expertsMarket.to} label="Shop Now" width="127px" style={ButtonStyle.dark} fontSize="14px" fontWeight="500"></ActionButton>
          </Buttons>    
        </SubscribeContainer>
      </Body>
  </DataContainer>
  );
};


const Icon = styled.img`
`

const SubscribeContainer = styled.div`
display: flex;
flex-direction: column;
justify-content:center;
align-items: center;
border: 1px solid ${Colors.fieldBorder};
padding: 20px;
border-radius: 8px;
`

const Body = styled.div`
 display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  width: 100%;
`;


const Text = styled.p`
font-size: 16px;
`

const Buttons=styled.div`
display: flex;
justify-content: flex-end;
width: 100%;
margin-top: 15px;
gap:10px;
`

const DataContainer = styled.div<{$isMobile:boolean}>`
   flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: ${Colors.whiteBackground};
  margin-left: ${props=>props.$isMobile? '20px' : 'none'};
  margin-right: ${props=>props.$isMobile? '20px' : 'none'};
  margin-top: ${props=>props.$isMobile? '20px' : 'none'};
`;
