import React from 'react'
import { Login } from '../components/registrations/Login'
import { AuthPage } from '../components/Layout/AuthPage'

export const LoginPage: React.FC = () => {
    return (
        <AuthPage>
            <Login />
        </AuthPage>
    )
}