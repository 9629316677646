
import styled from 'styled-components';
import { Wrapper,Title,P,List,ListItem,SubList,ListNumbersItem,ListNumbers,Link } from './index.style';
import { useNavigate } from 'react-router-dom';
import { routesMap } from '../../constants/routes';

export const Terms = () => {
    const navigate = useNavigate()
   

    return (
        <>
            <Wrapper>
                <P>Welcome to {process.env.REACT_APP_BRAND_NAME}! These Terms and Conditions ("Terms") govern your use of our website, www.{process.env.REACT_APP_DOMAIN_NAME}, and the products and services provided by {process.env.REACT_APP_BRAND_NAME} ("we," "us," or "our"). By accessing or using our website and services, you agree to comply with and be bound by these Terms. If you do not agree to these Terms, please do not use our website or services.</P>
                
               
                
               <ListNumbers>
                <ListNumbersItem $isTitle={true}>
                    General Terms
                    <ListNumbers>
                        <ListNumbersItem  $isTitle={true}>Acceptance of Terms</ListNumbersItem>
                        <P>By accessing or using our website and services, you agree to be bound by these Terms and our Privacy Policy. If you do not agree with any part of these Terms, do not access or use our website or services.</P>
                        <ListNumbersItem  $isTitle={true}>Changes to Terms</ListNumbersItem>
                        <P>{process.env.REACT_APP_BRAND_NAME} reserves the right to update or modify these Terms at any time without prior notice. Any changes will be effective immediately upon posting the revised Terms on our website. Your continued use of the website and services after the posting of changes constitutes your acceptance of such changes.</P>
                    </ListNumbers>
                </ListNumbersItem>

                <ListNumbersItem $isTitle={true}>
                    Use of the Website and Services
                    <ListNumbers>
                        <ListNumbersItem  $isTitle={true}>Eligibility</ListNumbersItem>
                        <P>To use our website and services, you must be at least 18 years old and capable of forming a legally binding contract. By using our website and services, you represent and warrant that you meet these requirements.</P>
                        <ListNumbersItem  $isTitle={true}>Account Registration</ListNumbersItem>
                        <P>You may need to register for an account to access certain features of our website and services. When registering, you agree to provide accurate, current, and complete information. You are responsible for maintaining the confidentiality of your account and password and for all activities that occur under your account.</P>
                        <ListNumbersItem  $isTitle={true}>Prohibited Activities</ListNumbersItem>
                        <P>You agree not to:</P>
                        <SubList>
                            <ListItem>Use the website or services for any unlawful purpose or in violation of these Terms.</ListItem>
                            <ListItem>Distribute viruses, malware, or other harmful software.</ListItem>
                            <ListItem>Interfere with or disrupt the operation of the website or services.</ListItem>
                            <ListItem>Attempt to gain unauthorized access to any part of the website or services.</ListItem>
                            <ListItem>Use the website or services to collect or store personal data about other users without their express permission.</ListItem>
                            <ListItem>Engage in any activity that imposes an unreasonable or disproportionately large load on our infrastructure.</ListItem>
                            
                        </SubList>
                    </ListNumbers>
                </ListNumbersItem>

                <ListNumbersItem $isTitle={true}>
                Products and Services
                    <ListNumbers>
                        <ListNumbersItem  $isTitle={true}>Trading Robots, Courses, and Signals</ListNumbersItem>
                        <P>{process.env.REACT_APP_BRAND_NAME} provides trading robots, trading courses, and trading signals designed to assist and educate traders. These products and services are for informational and educational purposes only and should not be considered financial advice.</P>
                        <ListNumbersItem  $isTitle={true}>No Guarantee of Profitability</ListNumbersItem>
                        <P>{process.env.REACT_APP_BRAND_NAME} does not guarantee profitability or specific results from using our trading robots, courses, or signals. Trading involves significant risk, and you may lose some or all of your investment. You acknowledge and agree that you use our products and services at your own risk.</P>
                        <ListNumbersItem  $isTitle={true}>Product Descriptions</ListNumbersItem>
                        <P>While we strive to provide accurate and up-to-date information, we do not guarantee that the descriptions of products or other content on our website are accurate, complete, reliable, current, or error-free.</P>
                        <ListNumbersItem  $isTitle={true}>Use of Trading Robots</ListNumbersItem>
                        <P>By using our trading robots, you agree to monitor your trading account regularly and ensure that it is adequately funded to support the trading activities. You acknowledge that trading robots are automated tools and that their performance can be affected by various factors beyond our control.</P>
                        <ListNumbersItem  $isTitle={true}>Trading Courses and Signals</ListNumbersItem>
                        <P>Our trading courses and signals are intended to provide educational content and trading strategies. We do not guarantee that following these strategies will result in profitable trades. You should use your own judgment and consider your financial situation before making any trading decisions based on our courses and signals.</P>      
                    </ListNumbers>
                </ListNumbersItem>

                <ListNumbersItem $isTitle={true}>
                Payments and Subscriptions
                    <ListNumbers>
                        <ListNumbersItem  $isTitle={true}>Payment Methods</ListNumbersItem>
                        <P>We accept various payment methods, including credit/debit cards, bank transfers, and online payment systems. By providing payment information, you represent and warrant that you are authorized to use the payment method and that the information is accurate and complete.</P>
                        <ListNumbersItem  $isTitle={true}>Subscription Terms</ListNumbersItem>
                        <P>Certain products and services may be offered on a subscription basis. Subscriptions will automatically renew at the end of each billing period unless you cancel your subscription in accordance with the instructions provided on our website.</P>
                        <ListNumbersItem  $isTitle={true}>Cancellation and Refund Policy</ListNumbersItem>
                        <P>You may cancel your subscription at any time by logging into your account and following the cancellation instructions. Due to the digital nature of our products and services, we generally do not offer refunds. Exceptions may be made in certain circumstances, such as technical issues that we are unable to resolve. To request a refund, contact our customer support team with your order details and a description of the issue. All refund requests will be reviewed on a case-by-case basis.</P>
                        <ListNumbersItem  $isTitle={true}>Pricing Changes</ListNumbersItem>
                        <P>{process.env.REACT_APP_BRAND_NAME} reserves the right to change the pricing of our products and services at any time. We will provide notice of any pricing changes on our website. Your continued use of the services after the effective date of the pricing change constitutes your acceptance of the new price.</P>
                        <ListNumbersItem  $isTitle={true}>Taxes</ListNumbersItem>
                        <P>You are responsible for any taxes, duties, or other governmental fees and charges that may be imposed on your purchase or use of our products and services.</P>
                    </ListNumbers>
                </ListNumbersItem>

                <ListNumbersItem $isTitle={true}>
                Intellectual Property
                    <ListNumbers>
                        <ListNumbersItem $isTitle={true}>Ownership</ListNumbersItem>
                        <P>All content on our website, including text, graphics, logos, images, and software, is the property of {process.env.REACT_APP_BRAND_NAME} or its licensors and is protected by copyright, trademark, and other intellectual property laws.</P>
                        <ListNumbersItem $isTitle={true}>Limited License</ListNumbersItem>
                        <P>{process.env.REACT_APP_BRAND_NAME} grants you a limited, non-exclusive, non-transferable, and revocable license to access and use our website and services for personal and non-commercial use. This license does not include the right to modify, distribute, or reproduce any content without our prior written consent.</P>
                        <ListNumbersItem $isTitle={true}>Trademarks</ListNumbersItem>
                        <P>{process.env.REACT_APP_BRAND_NAME} and other trademarks, service marks, graphics, and logos used in connection with our website and services are trademarks or registered trademarks of {process.env.REACT_APP_BRAND_NAME} or its licensors. You are granted no right or license to use any of these trademarks.</P>
                        <ListNumbersItem $isTitle={true}>User-Generated Content</ListNumbersItem>
                        <P>If you submit any content to our website, including reviews, comments, or other material, you grant {process.env.REACT_APP_BRAND_NAME} a non-exclusive, royalty-free, perpetual, and worldwide license to use, reproduce, modify, adapt, publish, and display such content in any media. You represent and warrant that you have the right to grant this license and that the content does not violate any third party’s rights.</P>
                    </ListNumbers>
                </ListNumbersItem>

                <ListNumbersItem $isTitle={true}>
                Disclaimers and Limitation of Liability
                    <ListNumbers>
                        <ListNumbersItem $isTitle={true}>Disclaimers</ListNumbersItem>
                        <P>{process.env.REACT_APP_BRAND_NAME} provides its website and services on an "as is" and "as available" basis. We do not warrant that our website or services will be uninterrupted, error-free, or free of viruses or other harmful components. To the fullest extent permitted by law, {process.env.REACT_APP_BRAND_NAME} disclaims all warranties, express or implied, including warranties of merchantability, fitness for a particular purpose, and non-infringement.</P>
                        <ListNumbersItem $isTitle={true}>Limitation of Liability</ListNumbersItem>
                        <P>In no event shall {process.env.REACT_APP_BRAND_NAME} be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of our website or services, whether based on warranty, contract, tort, or any other legal theory, and whether or not we have been advised of the possibility of such damages.</P>
                        <ListNumbersItem $isTitle={true}>Maximum Liability</ListNumbersItem>
                        <P>Our maximum liability to you for any claims arising out of or related to your use of our website or services shall be limited to the amount you paid to {process.env.REACT_APP_BRAND_NAME} in the twelve (12) months preceding the event giving rise to the claim.</P>
                    </ListNumbers>
                </ListNumbersItem>
                <ListNumbersItem $isTitle={true}>
                Indemnification
                <P>You agree to indemnify, defend, and hold harmless {process.env.REACT_APP_BRAND_NAME}, its affiliates, and their respective directors, officers, employees, and agents from and against any and all claims, liabilities, damages, losses, and expenses, including reasonable attorneys' fees, arising out of or in any way connected with your use of our website or services, your breach of these Terms, or your violation of any law or the rights of any third party.</P>
                </ListNumbersItem>

                <ListNumbersItem $isTitle={true}>
                    Governing Law and Dispute Resolution
                    <ListNumbers>
                        <ListNumbersItem $isTitle={true}>Governing Law</ListNumbersItem>
                        <P>These Terms shall be governed by and construed in accordance with the laws of the Republic of Cyprus, without regard to its conflict of law principles.</P>
                        <ListNumbersItem $isTitle={true}>Dispute Resolution</ListNumbersItem>
                        <P>Any disputes arising out of or in connection with these Terms shall be resolved through good faith negotiations between the parties. If the parties are unable to resolve the dispute through negotiations, the dispute shall be submitted to binding arbitration in accordance with the rules of the Republic of Cyprus. The arbitration shall be conducted in Nicosia, Cyprus, in the English language. The decision of the arbitrator shall be final and binding, and may be entered as a judgment in any court of competent jurisdiction.</P>
                        <ListNumbersItem $isTitle={true}>Class Action Waiver</ListNumbersItem>
                        <P>You agree that any disputes against {process.env.REACT_APP_BRAND_NAME} will be resolved on an individual basis, and you waive your right to participate in a class action lawsuit or class-wide arbitration.</P>
                    </ListNumbers>
                </ListNumbersItem>

                <ListNumbersItem $isTitle={true}>
                    Miscellaneous
                    <ListNumbers>
                        <ListNumbersItem $isTitle={true}>Severability</ListNumbersItem>
                        <P>If any provision of these Terms is found to be unlawful, void, or unenforceable, the remaining provisions will remain in full force and effect.</P>
                        <ListNumbersItem $isTitle={true}>Entire Agreement</ListNumbersItem>
                        <P>These Terms, along with our Privacy Policy, constitute the entire agreement between you and {process.env.REACT_APP_BRAND_NAME} regarding your use of our website and services and supersede all prior agreements and understandings.</P>
                        <ListNumbersItem $isTitle={true}>No Waiver</ListNumbersItem>
                        <P>No waiver of any term of these Terms shall be deemed a further or continuing waiver of such term or any other term, and {process.env.REACT_APP_BRAND_NAME}'s failure to assert any right or provision under these Terms shall not constitute a waiver of such right or provision.</P>
                        <ListNumbersItem $isTitle={true}>Assignment</ListNumbersItem>
                        <P>You may not assign or transfer these Terms, by operation of law or otherwise, without {process.env.REACT_APP_BRAND_NAME}'s prior written consent. Any attempt by you to assign or transfer these Terms without such consent will be null and void. {process.env.REACT_APP_BRAND_NAME} may assign or transfer these Terms at its sole discretion, without restriction.</P>
                        <ListNumbersItem $isTitle={true}>Force Majeure</ListNumbersItem>
                        <P>{process.env.REACT_APP_BRAND_NAME} shall not be liable for any failure or delay in the performance of its obligations under these Terms due to causes beyond its reasonable control, including but not limited to acts of God, war, terrorism, strikes, supply shortages, and interruptions of internet or telecommunications services.</P>
                        <ListNumbersItem $isTitle={true}>Third-Party Links</ListNumbersItem>
                        <P>Our website may contain links to third-party websites or services that are not owned or controlled by {process.env.REACT_APP_BRAND_NAME}. We are not responsible for the content, privacy policies, or practices of any third-party websites or services. You acknowledge and agree that {process.env.REACT_APP_BRAND_NAME} shall not be liable for any damages or losses caused by your use of or reliance on any third-party websites or services.</P>
                    </ListNumbers>
                </ListNumbersItem>

                <ListNumbersItem $isTitle={true}>
                Privacy Policy
                <P>Your use of our website and services is also governed by our Privacy Policy, which can be found <Link to={routesMap.privacyPolicy.to}>here</Link>. By using our website and services, you consent to the collection, use, and sharing of information as described in our Privacy Policy.</P>
                </ListNumbersItem>


                <ListNumbersItem $isTitle={true}>
                Contact Information
                <P>If you have any questions or concerns about these Terms, please contact us at:</P>
                <P><b>Email:</b>support@{process.env.REACT_APP_DOMAIN_NAME}</P>
                <P><b>Phone:</b>+357-24267217</P>
                <P><b>Address:</b>Strovolou 1, Strovolos 2011, Strovolos, Nicosia 2049, Republic of Cyprus</P>
                </ListNumbersItem>





                </ListNumbers>
                
            
                
                
            </Wrapper>
        </>
        
        
    );
};

const A = styled.div``
