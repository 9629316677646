import * as React from "react";
import { PageSwitcher } from "./PageSwitcher";
import { useFormHandling } from "./../../hooks/formUtils";
import { useState } from "react";
import { routesMap } from "./../../constants/routes";
import { DataArea } from "./DataArea";
import { useSession } from "./../../context/SessionContext";
import { NeedRegisterData } from "./NeedRegisterData";
import { SubscribeData } from "./SubscribeData";
import { ShopData } from "./ShopData";
import { useDeviceType } from "../../context/DeviceContext";
import { Filter } from "./filter";
import { useFilter } from "../../context/FilterContext";
import { DataProps } from "../data/DataArea";
import { RequestConfig } from "./../../api";
import { DataLayoutContainer, CloseButton,SidebarContainer,FilterContainer,Spacer,Head } from "./index.style";
import { FieldConfig, FieldType } from "../fields";
import { Icons } from "../../constants/Images";
import { useLocation } from "react-router-dom";


interface DataLayoutProps{
  fields:FieldConfig[]
  PublicCustomFilter?:React.ComponentType<any>;
  Element:React.ComponentType<DataProps>
  HeadElement?:React.ComponentType<any>;
  dataKey:'signals' | 'experts'  | 'courses' | 'customExperts'
  publicPage:string
  privatePage:string
  publicName:string
  privateName:string
  publicRequest:RequestConfig
  privateRequest:RequestConfig
  registerText:any
  registerTitle:string
  notSureText:any
  subscribeText:string
  icon:any
}


export const DataLayout = ({fields,PublicCustomFilter,Element,HeadElement,dataKey,publicPage,privatePage,publicName,privateName,publicRequest,privateRequest,registerText,registerTitle,notSureText,subscribeText,icon}:DataLayoutProps) => {
  const isPublicPage = window.location.pathname===publicPage
  
  const {isMobile} = useDeviceType()
  const {session} = useSession()
  const {showFilter,setShowFilter} = useFilter()
  const [page, setPage] = useState<number>(1);
  const requestType = isPublicPage?publicRequest:privateRequest
  const { handleInputChange, handleClick, serverRes, formValues } = useFormHandling({ requestType: requestType, params: { page: page } })
  const paging = serverRes?.paging
  const data = serverRes?.[dataKey]
  const hasDateFields = fields.filter(field=>field.type===FieldType.dateRange).length>0

  
  React.useEffect(() => {
    
    if(session.account || isPublicPage){
      if(hasDateFields){
        if(Object.keys(formValues).length>0){
          handleClick()
        }
      } else {
        handleClick()
      } 
      
    }

  }, [formValues,page]);

  const handlePage = (page: number) => {
    setPage(page)
  }


  const generateDataContainer = () =>{

    if(isPublicPage){
        return (<><DataArea  handlePage={handlePage} data={data} paging={paging} Element={Element}/></>)
    } else {
        if(!session?.account){
            const text = registerText
            return (<><ShopData icon={Icons.LoginIcon} text={text} btnText={"Login"} shopLink={routesMap.login.to}/></>)
        } else if(session.account.isMember && (dataKey==='signals' )){
            return (<><DataArea  handlePage={handlePage} data={data} paging={paging} Element={Element}/></>)
        }  else if( dataKey==='signals') {
            return (<><SubscribeData icon={icon} subText={notSureText} text={subscribeText}/></>)
        } else if(data && data?.length>0) {
          return (<><DataArea  handlePage={handlePage} data={data} paging={paging} Element={Element}/></>)
        } else {
          return (<><ShopData icon={icon}  text={subscribeText} shopLink={publicPage} /></>)
        }

    }
    
  }

  return (
    
    <DataLayoutContainer>
      
      {
        !isMobile && (!PublicCustomFilter || !isPublicPage) &&
        <FilterContainer>
        <PageSwitcher btn1Text={publicName} btn2Text={privateName} btn1Url={publicPage} btn2Url={privatePage} />
        <Spacer />
        <Filter fields={fields} serverRes={serverRes} handleInputChange={handleInputChange} />
        </FilterContainer>
      }
      {
        !isMobile && PublicCustomFilter && isPublicPage &&
        <FilterContainer>
          <PageSwitcher btn1Text={publicName} btn2Text={privateName} btn1Url={publicPage} btn2Url={privatePage} />
          <Spacer />
          <PublicCustomFilter serverRes={serverRes}/>
        </FilterContainer>
        
      }


      {
        isMobile && 
        <SidebarContainer $isOpen={showFilter}>
          <CloseButton onClick={()=>setShowFilter(false)}/>
          <Filter fields={fields} serverRes={serverRes} handleInputChange={handleInputChange} />
        </SidebarContainer>
        
      }
      <Head>
      
      {HeadElement &&isPublicPage &&
      <HeadElement serverRes={serverRes}/>
      }
       {generateDataContainer()}    
        </Head>
    
    </DataLayoutContainer>
  );
};

