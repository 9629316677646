import React, { useState } from 'react'
import styled from 'styled-components'
import { Colors } from '../../theme/colors'
import { ActionButton } from '../buttons/ActionButton'
import { useDeviceType } from '../../context/DeviceContext'
import { FieldConfig, FieldType,Field, TextFieldType } from '../fields'
import { Section,Title,FieldContainer,ExpertContainer,ExpertIcon,LeftContainer,SymbolName,RightContainer,MainTitle,GreyText,ScrollableContainer,Box,BoldText,BoxSection,ButtonsSection } from './index.style'
import { Bullets } from '../generic/Bullets'
import { Line } from '../generic/Line'
import { Images } from '../../constants/Images'


export interface FirstBoxProps{
    start:()=>void;
    price:number;

}

const bullets = [
    "Custom Automation: Convert your unique trading strategies into automated EAs for MT4 and MT5.",
    "Advanced Indicators: Develop specialized indicators to enhance your market analysis and decision-making.",
    "Risk Management Tools: Implement sophisticated risk management solutions to safeguard your investments.",
    "Precision and Speed: Benefit from precise, rapid trade execution without the influence of emotions.",
]

export const FirstBox: React.FC<FirstBoxProps> = ({start,price}) => {
    const { isMobile } = useDeviceType()
    
    return (
        
            <>
             <ExpertIcon $isMobile={isMobile} src={Images.ExpertBuild} />
             <ExpertContainer id="expt-cont">
            <Section className="section" >
                        <LeftContainer>
                            <MainTitle>
                                <SymbolName>{"Looking to Build Your Own Expert Advisor?"}</SymbolName>
                            </MainTitle>

                        </LeftContainer>
                        <RightContainer>
                            <MainTitle>
                                <SymbolName>Starting From ${price?.toFixed(2)}</SymbolName>
                            </MainTitle>
                        </RightContainer>
                    </Section>
                    

                    <Section className="section">
                        <Title>
                            <GreyText>Elevate your trading game with custom-built Expert Advisors (EAs) for MT4 and MT5. Our team of seasoned developers transforms your unique trading strategies into powerful, automated solutions tailored to your needs. Whether you seek automated trading systems, advanced indicators, or specialized risk management tools, we provide comprehensive development services to optimize your trading performance. Experience the benefits of precision, speed, and emotion-free trading with a bespoke EA designed just for you.</GreyText>
                        </Title>
                
                    </Section >

                    <Section className="section">
                
                        <Bullets rows={bullets} />
                    </Section >
                    <>
                        <Line></Line>
                        <ButtonsSection>
                            <ActionButton label="Start Now" onClick={start}/> 
                        </ButtonsSection>
                    </>

                    </ExpertContainer>


       
            
            </>
           
    )
}
