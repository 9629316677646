import styled from "styled-components";
import { Colors } from "../../theme/colors";
import React from "react";

import { Line } from "../generic/Line";
import { AddToCartButton } from "../buttons/AddToCartButton";
import { ProdcutType } from "../buttons/AddToCartButton";
import { useDictionary } from "../../context/DictionaryContext";
import { Bullets } from "../generic/Bullets";
import { useDeviceType } from "../../context/DeviceContext";
import { Images } from "../../constants/Images";





export const MembershipPromo: React.FC = () => {
const {translate} = useDictionary()
const {isMobile} = useDeviceType()





  return (
    <>
    <PromoTitle>
              <Title>Commonly purchased with:</Title>
        </PromoTitle>
      <Container $isMobile={isMobile}>
        

        <Maincontainer>
            <Image src={Images.App} />
        <SubscribeContainer>
        <Section className="section" >
            <LeftContainer>
              <Title>{translate("membership_title")}</Title>
            </LeftContainer>
            <RightContainer>
              <Title>$100/mo</Title>
            </RightContainer>

        </Section>
        <Section>
          <Text>
              {translate("membership_p1")}
          </Text>
        </Section>

        <Section>
          <BenefitArea>
            <SubTitle>
            {translate("membership_subtitle")}
            </SubTitle>
            <Bullets rows={[translate("membership_benefit1"),translate("membership_benefit2")]}/>
            
            
          </BenefitArea>
        </Section>

        



        </SubscribeContainer>
        

        </Maincontainer>
        
        <Line/>
          <Buttons>
            <AddToCartButton itemId={1} productType={ProdcutType.memberProgram}/>
          </Buttons>  
      </Container>

    </>
  );
};




const Container = styled.div<{$isMobile:boolean}>`
background-color: ${Colors.whiteBackground};
   flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid ${Colors.fieldBorder};
  padding: 20px;
  border-radius: 8px;
  justify-content: flex-start;
  margin-left: ${props=>props.$isMobile? '20px' : 'none'};
  margin-right: ${props=>props.$isMobile? '20px' : 'none'};
  margin-top: ${props=>props.$isMobile? '20px' : 'none'};
`;

const Maincontainer = styled.div`
display: flex;


align-items: center;
padding-top: 30px;

gap:20px;
`


const SubscribeContainer = styled.div`
display: flex;
flex-direction: column;
justify-content:flex-start;



`


const Section = styled.div`
display: flex;
justify-content: space-between;
width: 100%;
/* flex-direction: column; */
gap:10px;
margin-top: -15px;
margin-bottom: 20px;
`

const Title = styled.h3`
margin-top: -5px;
margin-bottom: 0px;
color: ${Colors.blackText};
font-size: 18px;
font-weight: 600;
`

const SubTitle = styled.div`
font-size: 14px;
color:${Colors.blackText};
font-weight:500;
`

const Text = styled.div`
font-size: 14px;
color:${Colors.text};
`

const BenefitArea = styled.div`
display: flex;
flex-direction:column;
justify-content: flex-start;
width: 100%;
background-color:${Colors.messageBox};
border-radius: 8px;
padding: 20px;
`

const Image = styled.img`
 position: flex;
 
 width: 150px;
  
`


const VPoint = styled.ul`
 display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 14px;
  gap: 10px 20px;
  margin-left: 20px;
  padding: 0;
  color: ${Colors.text};
`

const V = styled.li`
 display: flex;
  flex-wrap: wrap;
  gap:10px;
  margin-left: 10px;
  padding: 0;
  position: relative; /* Required for positioning the custom image */
  list-style-type: none; /* Remove default bullet point */
  line-height: 20px; /* Adjust according to your design */
`


const LeftContainer = styled.div`
display: flex;
align-items: center;
gap:10px;
`

const PromoTitle = styled.div`
margin: 20px 0px;
display: flex;
align-self: flex-start;
justify-content: flex-start;
gap:10px;
`
const RightContainer = styled.div`
padding: 5px;
display: flex;
gap:5px;
`


const Buttons=styled.div`
display: flex;
justify-content: flex-end;
width: 100%;
margin-top: 15px;
gap:10px;
`
