import React, { useEffect, useState } from 'react';
import { AuthPage } from '../components/Layout/AuthPage';
import { useFormHandling } from '../hooks/formUtils';
import { RequestConfigs } from '../api';
import { useLocation, useNavigate } from 'react-router-dom';

import { Auth } from '../api';
import { ChallengeIframe } from '../components/auth/ChallengeIframe';
import { useLoader } from '../context/LoaderContext';
import { accountRoutesMap } from '../constants/routes';
import styled from 'styled-components';
import { Colors } from '../theme/colors';
import { Backgrounds } from '../constants/Images';
import { DefaultPage } from '../components/Layout/DefaultPage';

export const ChallengePage: React.FC = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const internalId = queryParams.get('internalId');
    const acsURL = queryParams.get('acsURL');
    const creq = queryParams.get('creq');
    const {setLoading} = useLoader()

    const { handleInputChange, handleClick, serverRes } = useFormHandling({ requestType: RequestConfigs.challenge, params: {  internalId } });

    useEffect(() => {
        handleClick();
        setLoading(false)
    }, []);



    useEffect(() => {
        if (serverRes?.succeeded && serverRes.auth?.status) {
            if(serverRes.auth.status==="Y" || serverRes.auth.status==="N"){
                console.log("complete flow with status:"+serverRes.auth.status)
            }  else {
                console.log("unexpected result:",serverRes)
            }
        }else if(serverRes?.succeeded===false || serverRes?.purchases){
            navigate(accountRoutesMap.pay.to, { state: serverRes });
        } 
    }, [serverRes]);

    return (
        <AuthPage>
            <MainContainer>
                <ChallengeIframe acsURL={acsURL} creq={creq} />
            </MainContainer>
       
        </AuthPage>
        
        
    );
};

const Body = styled.div`
  display: flex;
  justify-content: center;
  /* align-items: center; // Center content vertically */
  width: 100%;
  
  min-height: 80vh; // Minimum height to cover the full viewport height
  background-image: url(${Backgrounds.MainBackground});
  background-size: cover;
  background-position: center; // Ensure the background is centered

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const MainContainer = styled.div`
display: flex;
  justify-content: center;
  flex-direction:column;
  align-items:center;
  margin-bottom:auto;
  /* border: solid 1px ${Colors.fieldBorder}; */
  /* margin-left: 24px;
  margin-right: 24px; */
  /* padding: 32px; */
  /* padding-top: 28px; */
  /* border-radius: 16px; */
  /* background-color:${Colors.whiteBackground}; */
  width: 100%;
  height: 500px;
  max-width: 388px; // Fixed width, adjust as needed
`
