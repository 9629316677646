import { useNavigate } from "react-router-dom";
import { Icons } from "../../../constants/Images";
import { Colors } from "../../../theme/colors";
import { KTText } from "../../Text";
import { ArrowButton } from "../../buttons/ArrowButton";
import { EmpowerContainer } from "../index.style";
import { EmpowerWrapper, TopContainer } from "./index.style";
import { routesMap } from "../../../constants/routes";
import styled from "styled-components";
import { Images } from "../../../constants/Images";
import { useDeviceType } from "../../../context/DeviceContext";
import { useDictionary } from "../../../context/DictionaryContext";
//Empower Your Trades
export const Empower = () => {
  const navigate = useNavigate()
  const {isMobile} = useDeviceType()
  const {translate} = useDictionary()
  return (
    
    <EmpowerContainer>
      
      <EmpowerWrapper>
      
        <TopContainer>
          <KTText
            fontSize={56}
            fontWeight={500}
            lineheight={80}
            color={`${Colors.whiteBackground}`}
            textalign="center"
          >
            {translate("home_hero_title")} {/* Empower Your Trades */}
            
          </KTText>
          <KTText
            fontSize={20}
            fontWeight={400}
            lineheight={23}
            textalign="center"
            color={`${Colors.whiteBackground}`}
          >
            {translate("home_hero_subtitle")}
          </KTText>
        </TopContainer>
        <ArrowButton
          label={translate("btn_start_now")}
          iconHeight={10}
          iconWidth={10}
          iconUrl={Icons.RightArrow}
          background={`${Colors.primary}`}
          width={"auto"}
          handleClick={() => navigate(routesMap.signals.to)}
          height={44}
          color={`${Colors.whiteBackground}`}
          borderradius={8}
          fontSize={14}
          fontWeight={400}
        />
      </EmpowerWrapper>

    </EmpowerContainer>
  );
};

