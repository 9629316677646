import * as React from "react";
import styled from "styled-components";
import { AvatarBtn } from "../../../../buttons/avatarBtn";
import { useSession } from "../../../../../context/SessionContext";
import { ActionButton,ButtonStyle } from "../../../../buttons/ActionButton";
import { accountRoutesMap  } from "../../../../../constants/routes";
import { Colors } from "../../../../../theme/colors";



export const Profile: React.FC= () => {
    const {session} = useSession()

  return (

    <ProfileWrapper>
    <UserAvatarWrapper>
      <AvatarBtn isMobile={true}/>
      <UserInfo>
        <UserName>{session.account?.name || "name test"}</UserName>
        <UserEmail>{session.account?.emailAddress || "test@test.com"}</UserEmail>
      </UserInfo>
    </UserAvatarWrapper>
      <ActionButton route={accountRoutesMap.logout.to} width="76px" height="36" label="Logout" style={ButtonStyle.light} />
  </ProfileWrapper>


    
  );
};

const ProfileWrapper = styled.div`
  width: 100%;
  /* margin-left:20px;
  margin-right:20px; */
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
`;


const UserAvatarWrapper = styled.div`
display: flex;
justify-content: space-between;
gap: 16px;
`;


const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const UserName = styled.div`
  color: ${Colors.text};
  font-family: Roboto, sans-serif;
  font-weight: 500;
  line-height: 1.43;
`;

const UserEmail = styled.div`
  color: ${Colors.text};
  font-family: Roboto, sans-serif;
  font-weight: 400;
  margin-top: 4px;
`;








