import React, { useEffect, useState } from 'react';
import { DefaultPage } from '../components/Layout/DefaultPage';
import { useFormHandling } from '../hooks/formUtils';
import { RequestConfigs } from '../api';
import { useLocation } from 'react-router-dom';
import { FingerPrintIframe } from '../components/auth/FingerPrintIframe';
import { Auth } from '../api';
import { useNavigate } from 'react-router-dom';
import { paymentsRoutesMap,accountRoutesMap } from '../constants/routes';

export const FingerPrintPage: React.FC = () => {
    
    
    const navigate = useNavigate()
   
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const internalId = queryParams.get('internalId');
    const methodURL = queryParams.get('methodURL');
    const threeDSMethodData = queryParams.get('threeDSMethodData');

    const { handleInputChange, handleClick, serverRes } = useFormHandling({ requestType: RequestConfigs.fingerPrint, params: { internalId } });

    useEffect(() => {
        handleClick();
    }, []);

    useEffect(() => {
        if (serverRes?.succeeded && serverRes.auth?.status) {
            if(serverRes.auth.status==="Y" || serverRes.auth.status==="N"){
                console.log("complete flow with status:"+serverRes.auth.status)
            } else if (serverRes.auth.status==="C" && serverRes.auth?.creq && serverRes.auth?.acsURL && internalId) {
                const params = {
                    creq:serverRes.auth?.creq,
                    acsURL:serverRes.auth?.acsURL,
                    internalId:internalId
                }
                const searchParams = new URLSearchParams(params).toString();
                navigate(paymentsRoutesMap.challenge.to+"?"+searchParams)
            }  else {
                console.log("unexpected result:",serverRes)
            }
        } else if(serverRes?.succeeded===false || serverRes?.purchases){
            navigate(accountRoutesMap.pay.to, { state: serverRes });
        }
    }, [serverRes]);

    return (
        <DefaultPage>
            <FingerPrintIframe methodURL={methodURL} threeDSMethodData={threeDSMethodData}/>    
        </DefaultPage>
    );
};
