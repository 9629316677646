
import Joi from "joi";
import { RequestConfigs } from "../../api";
import { routesMap } from "../../constants/routes";
import { Icons } from "../../constants/Images";
import { Expert } from "./expert";
import { TextLink } from "../generic/TextLink";   
import { DataLayout } from "../data";
import { FieldType,FieldConfig,TextFieldType } from "../fields";


const fields:FieldConfig[] = [
  {
    type: FieldType.text,
    name: "search",
    label: "Search",
    joiSchema: Joi.string().allow(null, ''),
    typeOptions:{type:TextFieldType.search}
  },
  {
    type: FieldType.multiSelect,
    name: "class",
    label: "Class",
    joiSchema: Joi.array(),
    typeOptions:{
      options: [
        { name: "Standard", value: 1 },
        { name: "Gold", value: 2 },
        { name: "Prime", value: 3 },
      ],
    }
  },
  {
    type: FieldType.multiSelect,
    name: "platformType",
    label: "Platform Type",
    joiSchema: Joi.array(),
    typeOptions:{
      options: [
        { name: "MT4", value: 1 },
        { name: "MT5", value: 2 },
      ],
    }
  },
  {
    type: FieldType.multiSelect,
    name: "tradingType",
    label: "Trading Type",
    joiSchema: Joi.array(),
    typeOptions:{
      options: [
        { name: "Day Trade", value: 1 },
        { name: "Swing", value: 2 },
      ],
    }
  },
  {
    type: FieldType.filterSelect,
    name: "rating",
    label: "Rating",
    joiSchema: Joi.number(),
    typeOptions:{
      options: [
        { name: "1+", value: 1 },
        { name: "2+", value: 2 },
        { name: "3+", value: 3 },
        { name: "4+", value: 4 },
        { name: "5+", value: 5 },
      ],
    }
  },
]

const registerText = (<>You will need to <TextLink route={routesMap.login.to} fontSize="16px">Login</TextLink> in order to see your experts. Don’t have account yet? <TextLink route={routesMap.signup.to} fontSize="16px">Signup</TextLink> </>)
const notSureText = (<>You haven't selected any experts yet. <TextLink route={routesMap.expertsMarket.to}>Purchase now</TextLink>  to get started!</>)
export const Experts = () => {


  return (
    <>
    <DataLayout 
      fields={fields} 
      Element={Expert} 
      dataKey="experts" 
      publicPage={routesMap.expertsMarket.to}
      privatePage={routesMap.myExperts.to}
      publicName="Experts Market"
      privateName="My Experts"
      publicRequest={RequestConfigs.expertMarket}
      privateRequest={RequestConfigs.myExperts}
      registerText={registerText}
      registerTitle="MY Experts"
      notSureText={notSureText}
      subscribeText="You haven't selected any experts yet. Purchase now to get started!"
      icon={Icons.ExpertIcon}

    />
    </>
    
  );
};

