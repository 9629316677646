import styled from "styled-components";
import { Icons } from "../../constants/Images";
import { Colors } from "../../theme/colors";

interface Row {
    title?:string;
    text:string;
}

interface BulletsProps {
    rows:Row[] | string[]
    fontSize?:string;
}

export const Bullets: React.FC<BulletsProps> = ({ rows, fontSize }) => {

    const rowsArrOFObj:Row[] = []
    for(let i in rows){
        const row = rows[i]
        if (typeof row === "string") {
            const splited = row.split(/:(.+)/).slice(0, 2).map((s, i) => i === 1 ? s.trim() : s);
            const text = splited[1]? splited[1]:splited[0]
            const title = splited[1]? splited[0]+":":undefined
            rowsArrOFObj.push({title,text})
                
           
        } else {
            rowsArrOFObj.push(row)
        }

    }
    

   


    const generateBullets = () => {
        return rowsArrOFObj.map((row, index) => {
            
            
            return (
                <V key={index}>
                    <VImage src={Icons.PurpleV} />
                    <b>{row?.title} </b>{row?.text}
                </V>
            );
        });
    };

    

    return (
        <>
            <VPoint $fontSize={fontSize}>{generateBullets()}</VPoint>
        </>
    );
};




const VPoint = styled.ul<{$fontSize?:string}>`
 display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  font-size: ${props=>props.$fontSize?props.$fontSize:"14px"};
  gap: 15px 30px;
  margin-left: 20px;
  padding: 0;
  color: ${Colors.text};
`
const V = styled.li`
  margin-left: 10px;
  padding: 0;
  position: relative; /* Required for positioning the custom image */
  list-style-type: none; /* Remove default bullet point */
  line-height: 20px; /* Adjust according to your design */
`

const VImage = styled.img`
 position: absolute;
  left: -25px; /* Adjust the positioning of the custom image */
  top: 50%; /* Adjust the positioning of the custom image */
  transform: translateY(-50%); /* Center vertically */
`
